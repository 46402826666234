.child_category_image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top center;
  background-color: #ffffff;
  background-repeat: no-repeat;
  height: 180px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4) inset;
  border-radius: 2px;
}

.center {
  display: table;
  margin: 0 auto;
}

.child-large-photo {
  max-height: 100px;
}

.child-list-photo {
  width: 80px;
}

.child-list-photo--muted {
  opacity: 0.5;
}

.child-name-dropdown {
  font-weight: 500;
}

.child_breakline {
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
}

.child_panel {
  width:185px;
  margin: 15px;
  display:inline-block;
}

.child_panel_heading {
  padding-left: 8px;
  padding-right: 8px
}

.child_panel_body {
  padding: 8px;
  border-radius: 3px;
}

.child_name {
  padding: 8px;
  border-radius: 3px;
  font-size: 15px;
}

.child-tag.dropdown {
  display: inline-block;
  overflow: visible;
}

// Child Select

.child-select2-portrait {
  width: 25px;
}

.no-padding {
  padding: 0 !important;
}

.no-margins {
  margin : 0;
}

.child-select-confirm-buttons-container {
  z-index: 1051;
  width: 100%;

  &.attached {
    position: fixed;
    bottom: 0;

    @media (min-width: 768px) {
      margin-left: -7px;
    }
  }

  &.detached {
    .child-select-confirm-buttons {
      border-radius: 6px;
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }
  }

  .child-select-confirm-buttons {

    background: white;
    padding: 10px;

    border-top: 1px solid #e5e5e5;

    @media (min-width: 768px) {
      width: 598px;
      margin: auto;
    }

    .pull-left {
      padding-top: 8px;
    }

    .pull-right {
      text-align: right;
    }
  }
}

.child-select-item {
  i.fa-square-o {
    margin-right: 1px;
  }
}

.child-filter-eypp-label-hidden {
  .child-filter-eypp-label {
    display: none;
  }
}

.verification-link {
  vertical-align: top;
  margin-top: 20px;
  display: inline-block;
}

.child-name-wrap {
  word-break: break-all;
}
