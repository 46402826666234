.invitation-qr-code {
  width: 100%;
  max-width: 400px;
}

.invitation-info {
  font-size: 16px;
  a {
    .fa {
      margin: 0 0.25em 0 0.5em;
    }
  }
}

.child-login-profile-panel {
  width: 100%;
  text-align: left;

  @media only screen and (min-width: 700px) {
    width: 47.5%;
  }

  @media only screen and (min-width: 992px) {
    width: 31%;
  }
}

#childLoginDetailsMain {
  .invitation-short-key-words {
    font-family: monospace;
    font-size: 30px;
    display: inline-block;
    border: 1px solid #5A5A5A;
    border-radius: 15px;
    padding: 10px 15px;
    color: #d0454c;
  }

  .invitation-url {
    font-family: monospace;
    font-size: 20px;
    display: inline-block;
    border: 1px solid #5a5a5a;
    border-radius: 15px;
    padding: 10px 15px;
    color: #4568d0;
    width: 90%;
  }
}

#simpleLoginKeyContainer:fullscreen,#qrCodeDetailsContainer:fullscreen {
  background: white;
  padding-top: 30px;

  font-size: 2vw;

  h2 {
    font-size:4vw;
  }

  .invitation-short-key-words {
    font-size:5vw;
  }

  .invitation-qr-code {
    width: 40%;
    max-width: none;
  }

  .long-url {
    font-size: 1vw;
  }
}

.manage-child-login-actions {
  margin: 0 10px 0 0;
  min-width: 150px;
  font-size: 0.85em;
}

#child_authentication_password_modal {
  .obfuscated {
    position: relative;
    label {
      height: 60px;
      width: 100%;
      user-select: none;
      position: absolute;
      z-index: 2;
      top: 0;
    }
    input {
      position: absolute;
      top: 25px;
      font-family: 'text-security-disc';
    }
  }
  .password-box {
    height: 60px;
    position: relative;
    button {
      position: absolute;
      top: 27px;
      right: 2px;
      z-index: 3;

      @media (max-width: 480px) {
        padding-right: 0;
      }
    }

    .toggle-text {
      display: inline-block;
      width: 180px;

      @media (max-width: 480px) {
        font-size: 10px;
        width: 150px;
      }
    }

    .toggle-icon-wrapper {
      display: inline-block;
      text-align: center;
      color: black;
      width: 5px;
    }
  }
  .staff-login {
    border: 10px solid #a94442;
  }
}

.child-location-icon {
  max-width: 100px;
}

.child-index-icon {
  display: block;
  float: left;
  padding-right: 10px;
}

.child-login-index .panel-heading {
  font-size: 18px;
  line-height: 20px;
}
.child-authentication-tiles {
  overflow: auto;
  margin-right: -15px;
}

.child-authentication-tile {
  margin-bottom: 10px;
  text-align: left;
  white-space: normal;
  display: flex;
  width: 100%;
  float: left;
  margin-right: 15px;

  @media only screen and (min-width: 927px) {
    width: 47.5%;

    &.child-authentication-tile__switch {
      width: 30%;
    }
  }
}

.child-authentication-tile--only-photo {
  @extend .child-authentication-tile;

  display: inline-block;
  text-align: center;
  margin-right: 15px;

  position: relative;
  min-height: 1px;
  float: left;

  width: auto;
}

.child-authentication-tile__photo {
  width: 150px;
  flex-shrink: 0;
}

.child-authentication-tile__name {
  font-size: 2em;
  margin-left: 10px;
}

.child-authentication-password-modal__photo-container {
  text-align: center;
}

.child-authentication-password-modal__photo {
  width: 150px;
  height: 150px;
}

.child-authentication-staff-login-container {
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px 15px 0 0;
}

.match-height.child-login-setup-computers {
  .list-group-item { height: 100% }
}

[data-filter-section="password_visibility"] div.form-group {
  font-size: 0; // IE11: to hide the nbsp characters between inputs
  label {
    font-size: 14px;
    min-width: 95%;
    position: relative;
  }
}

.btn-block-no-top-margin {
  margin-top: 0 !important;
}

.child-list-content {
  overflow: auto;
  display: block;
}

.child-login-page-photo {
  max-width: 100%;
  max-height: 200px;
}

.child-login-page-photo-preview {
  max-width: 500px;
  max-height: 180px;
}

#childrenList {
  .children-list {
    font-weight: bold;
    display: inline;
  }
}

#childrenList.collapse.in {
  display: inline;
}

#childrenList.collapsing {
  display: inline;
}

#childrenText a {
  cursor: pointer;
}

.list-group-item-child-login-page {
  h4 {
    color: #337ab7;
  }

  &:hover {
    h4 {
      text-decoration: underline;
    }
  }
}

.child-login-page-listing {
  & button {
    display: block;
    width: 100%;
    margin-top: 10px;

    @media (min-width: 576px) {
      display: inline;
      width: auto;
      margin: 0;
    }
  }
}

//#children-change-box {
//  margin: 10px 20px;
//  padding: 10px;
//  border-radius: 4px;
//  border: 1px solid;
//}

#children-change-box {
  h5 {
    margin-top: 0px;
    font-weight: bold;
  }

  &:empty {
    display: none!important;
  }
}
