
.obs-media-gallery {
  background-color: black;
}

.obs-media-gallery-main {
  text-align: center;
  padding: 5px;
}

.obs-media-gallery-main img {
  max-width: 100%;
  max-height: 100%;
}

.obs-media-gallery-thumbs {
  padding-left: 5px;
}

.obs-media-gallery-thumbs img {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.page-media-document-button, .page-media-audio-button {
  margin-bottom: 5px;
}

.page-media-document-button-filename {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-right: 5px;
}

.page-media-audio-button-filename {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-right: 5px;
}

.page-media-audio-button-duration {
  display: inline-block;
  max-width: 45px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: 5px;
}

.delete-page-media-button {
  @media (min-width: 1200px) {
    padding-right: 25px;
  }
}
