$timeline-color: #a6a6a6;

.timeline {
  .timeline-item {
    padding: 10px 15px;
    border-left: 2px solid $timeline-color;
    position: relative;

    &::after {
      width: 10px;
      height: 10px;
      display: block;
      top: 16px;
      position: absolute;
      left: -6px;
      border-radius: 10px;
      content: '';
      border: 2px solid $timeline-color;
      background: white;
    }
  }
}
