/* VIEW ACTIVITIES */

/* BROWSE ACTIVITIES */

  .activity-item {
    &.status-draft {
      border-left: 5px solid #f0ad4e;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #F6C53F; /* Old browsers */
      background: -moz-linear-gradient(left,  #F6C53F 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F6C53F), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6C53F', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-soft-deleted {
      border-left: 5px solid red;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #f9c7c7; /* Old browsers */
      background: -moz-linear-gradient(left,  #f9c7c7 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#f9c7c7), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9c7c7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-published-staff {
      border-left: 5px solid #5fc4fc;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9f4fc+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #c9f4fc; /* Old browsers */
      background: -moz-linear-gradient(left,  #c9f4fc 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9f4fc', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }

.activity-icon{
  color: #0e324a;
  text-align: center;
  font-size: 3em;

  @media only screen and (min-width: 768px){
    font-size: 7em;
  }
}

.activity-box-link {
  &:hover {
    text-decoration: none;

    h4 {
      text-decoration: underline;
    }
  }
}

.activity-template-notes {
  text-decoration: none!important;
  color: #555555;

  &:hover {
    text-decoration: none!important;
  }
}

.activity-template-grid-item {
  width: 100%;
  margin-bottom: 20px;

  //@media only screen and (min-width: 480px) {
  //  width: 47.5%;
  //}

  @media only screen and (min-width: 700px) {
    width: 47.5%;
  }

  @media only screen and (min-width: 992px) {
    width: 31%;
  }
}

#shared-activity-grid-fits {
  width: 100%;
  margin-bottom:55px;

  //@media only screen and (min-width: 480px) {
  //  width: 47.5%;
  //}

}


.shared-activity-grid-item {
  width: 100%;
  margin-bottom: 20px;

  //@media only screen and (min-width: 480px) {
  //  width: 47.5%;
  //}

  @media only screen and (min-width: 700px) {
    width: 47.5%;
  }

  @media only screen and (min-width: 992px) {
    width: 31%;
  }
}

.zero-activities-message {
  margin-left: 15px;
  margin-right: 15px;
}

/* MANAGE ACTIVITIES */


// ACTIVITY CATEGORY TAGS

@import "./activity_tags.scss";

.activity-tag-picker {

  button {
    border: none;
  }

  .activity-tag-picker-category {
    padding: 2px;
    @include activity-tag-backgrounds;

    .activity-tag-picker-category-inner {
      padding: 2px;
      margin: 0;

      .activity-tag-picker-category-preview-wrapper {
        background-color: white;
        border-radius: 3px;
        padding: 2px 5px 4px;
        line-height: 1.5;

        .btn-group {
          white-space: nowrap;

          .btn {
            float: none;
            display: inline-block;
          }
        }
      }

      .media-body {

        button {
          text-align: left;
          white-space: normal;

          [data-tag-name] {
            display: block;
            width: auto;
            overflow: hidden;
          }

          .activity-tag-picker-category-icons {
            display: block;
            width: auto;
            float: right;
            padding-left: 5px;
          }

          .activity-tag-picker-category-description {
            display: block;
            font-size: 0.8em;
          }

          .label[data-tag-count] {
            display: inline;
            &::before {
              content: attr(data-tag-count);
            }

            &:empty {
              display: inline;
            }

            &[data-tag-count="0"] {
              display: none;
            }
          }

          .activity-tag-picker-checked-icon {
            display: none;
          }
          .activity-tag-picker-unchecked-icon {
            display: inline;
          }

          &.active {
            .activity-tag-picker-checked-icon {
              display: inline;
            }
            .activity-tag-picker-unchecked-icon {
              display: none;
            }
          }
        }
      }
    }

    .activity-tag-picker-category-children {
      margin-left: 20px;
    }
  }

  >.activity-tag-picker-category {
    margin-top: 5px;
    background-color: #f5f5f5;

    &:first-child {
      margin-top: 0;
    }
  }
}

.activity-tag-quick-picker-root {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  @include activity-tag-backgrounds;

  &:last-child {
    margin-right: 0;
  }

  button {
    border: none;
  }

  .label[data-tag-count] {
    display: inline;
    &::before {
      content: attr(data-tag-count);
    }

    &:empty {
      display: inline;
    }

    &[data-tag-count="0"] {
      display: none;
    }
  }
}

.activity-tag-quick-picker-selected-tag {
  display: none;
  margin-bottom: 5px;

  .activity-tag-quick-picker-selected-tag-button {
    @include activity-tag-backgrounds;
  }

  &.activity-tag-quick-picker-selected-tag-selected {
    display: inline-block;
  }
}

.activity-tag-view {

  .activity-tag-view-category {
    padding: 2px 0 2px 2px;
    @include activity-tag-backgrounds;

    .activity-tag-view-category-inner {
      padding: 2px;
    }

    .activity-tag-view-category-name {
      width: auto;
      overflow: hidden; // BFC
    }

    .activity-tag-view-category-icon {
      width: auto;
      float: right;
    }

    .activity-tag-view-category-children {
      margin-left: 20px;
    }
  }

  >.activity-tag-view-category {
    margin-top: 5px;
    padding-right: 2px;
    background-color: #f5f5f5;

    &:first-child {
      margin-top: 0;
    }
  }
}

.activity-tag-quick-view {
  display: inline;
}

.activity-tag-quick-view-category {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid transparent;
  @include activity-tag-backgrounds;
}

.activity-tag-quick-preview-category {

  .activity-tag-quick-preview-category-inner {
    margin-bottom: 5px;

    .activity-tag-quick-preview-category-description {
      display: block;
      width: auto;
      overflow: hidden;
      padding-left: 10px;
    }

    .activity-tag-quick-preview-category-tag {
      display: block;
      width: auto;
      float: left;
    }

  }

  .activity-tag-quick-preview-category-children {
    &.activity-tag-quick-preview-category-children-inline {
      .activity-tag-quick-preview-category {
        width: auto;
        float: left;
        margin-right: 10px;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

}

.activity-tag-quick-preview-category-children {
  margin-left: 20px;
}

// REFLECTION VIEW

.activity-view-toggle-linked-obs-button {
  [data-show-icon] {
    display: none;
  }
  [data-hide-icon] {
    display: inline-block;
  }

  &.collapsed {
    [data-show-icon] {
      display: inline-block;
    }
    [data-hide-icon] {
      display: none;
    }
  }
}

// REFLECTION QUESTIONS

.activity-question-provider-panel{
  .panel-body {
    padding-bottom: 0;
  }
}

// TAG COUNT BUTTONS

.activity-tag-count-button {
  margin-bottom: 5px;
}

// REFLECTION LIST ITEM NOTES

.activity-notes-short {
  display: block;
  margin-bottom: 5px;
  word-wrap: break-word;
}

// REFLECTION LIST ITEM TAGS

.activity-item-tags {
  margin-top: 5px;

  [data-activity-no-children] {
    margin-bottom: 5px;
    cursor: help;
  }

  .child-tag {
    margin-top: 0;
  }

  .user-tag {
    margin-top: 0;
  }

  .btn-xs {
    margin-bottom: 5px;
  }
}

.activity-relative {
  position: relative;
}

.activity-absolute {
  position: absolute;
}

.activity-link {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.activity-frame:hover {
  -webkit-box-shadow: 0px 0px 7px 0px #62a8e9;
  -moz-box-shadow: 0px 0px 7px 0px #62a8e9;
  box-shadow: 0px 0px 7px 0px #62a8e9;

  .media-heading {
    color: #337ab7;
    text-decoration: underline;
  }
}
