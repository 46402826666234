.comments {
  font-size: 0.9em;
  background-color: #f7f7f7;
  padding: 10px 25px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.comments__group {
  padding: 0 15px;
  margin: 10px 0 0;

  & p {
    margin-bottom: 5px;
  }
}

.comments__row--highlight {
  animation: highlight-comment 2s;
}

.comments__group--separator {
  @extend .comments__group;

  margin-bottom: 10px;
  border-left: 3px solid lightgrey;
}

.comments__box-wrapper {
  position: relative;
}

.comments__box {
  display: block;
  box-sizing: padding-box;
  overflow: hidden;
  padding: 10px 35px 10px 10px;
  margin-bottom: 0;
  resize: none;
  height: 30px;
  min-height: 30px;
  border-radius: 7px;
}

.comments__submit {
  font-size: 1.5em;
}

.comments__box-error {
  background-color: #e48282;
  text-align: center;
  padding: 15px;
}

.comments__box-actions {
  font-size: 0.85em;
  margin-bottom: 5px;
}

.comments__pre-defined-height {
  min-height:50px;
}

.comments__user-name {
  font-weight: bold;
  margin-right: 10px;
}

.comments__no-content {
  font-size: 1.25em;
  text-align: center;
  padding-bottom: 15px;
}

//Animations

@keyframes highlight-comment {
  100% {
    background: lightgoldenrodyellow;
  }
}
