@use "reflection_tags";
@use "sass:map";
@use "sass:list";

$bespoke-colours: (
  "blue": #2b87d8,
  "red": #ff4136,
  "green": #2ecc40
);
$colours: map.merge(reflection_tags.$rainbow-colour-palette, $bespoke-colours);

/**
 * foreground colour overrides, to improve contrast
 * @link https://github.com/eyfs/tapestry/issues/9272
 */
$bespoke-colours-white-foreground: (
  "blue",
  "red",
  "rb-navy-purple",
  "rb-very-light-blue" // this this is neither very light, nor very blue
);
$bespoke-colours-black-foreground:(
  "rb-sea-serpent",
  "rb-ocean-green",
  "rb-paris-green",
  "rb-mantis",
  "rb-middle-green-yellow",
  "rb-aztec-gold"
);

$rainbow-colour-palette-white-foreground: list.join(
  reflection_tags.$rainbow-colour-palette-white-foreground,
  $bespoke-colours-white-foreground
);
