.dropdown-menu {
  border: none;
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 1px;
}

.navbar-nav sup {
  color: red;
}

@media print {

  /* Stop printing URLs after links */
  a[href]:after {
    content: none !important;
  }

}

.navbar-fixed-top .navbar-collapse {
  max-height: none;
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-nav-container.container {
    width: 100%;
    padding: 0 10px;

    #userDropDownContainer {
      margin-right: 10px;
    }
  }
}
