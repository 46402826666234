// Top Nav

.top-strip {

  position: relative;

  // Fixed header
  &__top {
    position: fixed;
    width: 100%;
    z-index: 1031;
  }

  // Stuff below fixed header
  &__bottom {
    padding-top: 72px;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }
  }
}

// Stuff below fixed header in child mode
.top-strip--child-mode .top-strip__bottom {
  padding-top: 51px;
}

.navbar.navbar-top {
  border: 0;

  .navbar-brand {
    padding-top: 8px;
    margin-left: -3px;
    padding-bottom: 0px;
    height: auto;
  }

  .navbar-toggle.pull-left {
    margin-left: 15px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    @media screen and (max-width: 767px) {
      padding: 5px 15px 5px 8px;
    }
  }

  #userDropDownContainer {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  #nav-user-dropdown-mobile {
    margin-top: -7.5px;
  }
}

// Bottom Nav

.navbar.navbar-bottom {

  .navbar-nav > li > a {
    border-radius: 16px;

    padding: 4px 10px;
    margin: 4px;

    &.highlighted {
      border: 2px solid yellow;
    }

    @media screen and (max-width: 767px) {
      padding: 8px 10px;
    }
  }
}

// Both Navs

.navbar-top, .navbar-bottom {
  // Position statically within top strip
  position: static;
  margin: 0;
  min-height: 0;
  border: 0;

  .navbar-nav > li > a {
    padding: 8px;
  }
}

// Child Mode Nav
.navbar-child {
  position: static;
  margin-bottom: 0;

  .navbar-header {
    display: flex;

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 200px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: 300px;
    }
    @media (min-width: 1200px) {
      max-width: 400px;
    }

    .navbar-brand {
      flex-grow: 1;
      padding: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      img {
        width: 40px;
        height: 40px;
        display: inline;
        border: 1px solid #fff;
      }
    }
  }

  #topstrip-mobile-dropdown {
    white-space: nowrap;
  }
}
