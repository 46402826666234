/* EYLF Assessment Counts */

.list-group-item[data-filter-children].only-item {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Charts */
.tracking-chart-wrapper {
  position: relative;
}

/* Montessori Related Suggestions */
.montessori-related-suggestion-toggle-button {
  [data-expand-icon] {
    display: none;
  }

  [data-collapse-icon] {
    display: inline-block;
  }

  &.collapsed {
    [data-expand-icon] {
      display: inline-block;
    }

    [data-collapse-icon] {
      display: none;
    }
  }
}

.montessori-related-suggestion-filter-full-width {
  display: block !important;
  width: 100% !important;
}

.montessori-related-suggestion-filter-align-top {
  vertical-align: top !important;
}

.montessori-related-suggestion-child-tag {
  display: inline-block;
  margin: 0 5px 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2px 5px;

  &.montessori-related-suggestion-child-tag-highlight {
    background-color: rgb(249, 222, 229);
  }
}

/* Reflection Counts */

.reflection-count-tag-filters {
  /* Undo the form-inline changes to form-groups and form-controls in here*/

  .form-group {
    display: block;
    margin-bottom: 15px;
    vertical-align: unset;
  }

  .form-control {
    display: block;
    width: 100%;
    vertical-align: unset;
  }
}

// New DM, areas of concern, group table

$dm2020-judgements-colour-palette: (
        "unsure": #d7bb2d, //#F0E158,
        "concerned": #dc919f,
        "ok": #a9cb65,
        "removed": #BBB,
);

.dm2020 {
  &__judgement-button {
    @each $judgement, $colour in $dm2020-judgements-colour-palette {
      &.#{$judgement} {
        color: darken($colour, 15%);
        background-color: desaturate(lighten($colour, 25%), 10%);
        border-color: lighten($colour, 20%);
        border-width: 2px;

        &:hover, &:focus {
          color: #fff;
          background-color: lighten($colour, 15%);
          border-color: $colour;
        }

        &:active, &.active {
          color: #fff;
          background-color: $colour;
          border-color: darken($colour, 33%);
          font-weight: bold;

          &:hover, &:focus {
            color: #fff;
            background-color: lighten($colour, 10%);
            border-color: $colour;
          }
        }
      }
    }
  }
}

.dm2020-aoc-group-table {

  &__table {
    width: 100%;
  }

  &__row {
    display: flex;
  }

  &__cell, &__cell-head {
    flex: 1 0 110px;
    font-weight: normal;
    padding: 8px;
    vertical-align: top;

    &.dm2020-finger-space-bottom {
      padding-bottom: 48px
    }
  }

  &__head {
    border-top: 36px solid white;
    border-bottom: 48px solid white;

    .dm2020-aoc-group-table__row {
      background: #fafafa;
    }
  }

  &__judgement-count {
    @each $judgement, $colour in $dm2020-judgements-colour-palette {
      & .#{$judgement} a {
        color: darken($colour, 25%);
      }
    }
  }

  &__section-heading {
  }

  &__section-title {
    font-weight: bold;
    font-size: 1.5em;
    display: inline-block;
    margin-bottom: 15px;

    // Sticky so it doesn't scroll with the table
    @media (min-width: 768px) {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
    }

    // Fix the width at the breakpoints so things floated to the right are in the correct place
    @media (min-width: 768px) and (max-width: 991px) {
      width: 650px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 870px;
    }
    @media (min-width: 1200px) {
      width: 1070px;
    }

    // Add some spacing to the button at smaller break points so it doesn't crowd title
    // We will be horizontally scrolling at this point, so button may be cropped
    @media (max-width: 767px) {
      .btn {
        margin-left: 1em;
      }
    }

    small {
      font-size: 66%;
      font-weight: normal;
    }
  }

  &__child-row-heading {
    border-top: 1px solid #dddddd;
  }

  &__body-scroller {
    overflow-x: auto;
  }

  @media (min-width: 768px) {
    &__row-heading {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
      border-right: 3px solid #dddddd;
    }
  }

  &__child-column {
    width: 110px;
  }

  &__area-column {
    width: 110px;
  }

  &__photo {
    width: 100%;
    height: auto;
  }

  &__cg-photo {
    width: 80%;
    height: auto;
  }

  &__judgement-form {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  .btn {
    white-space: normal;
  }

  .dropdown, .dropup {
    position: static;
  }

  .form-group {
    margin-bottom: 5px;
  }

  // Limit width of table when it does not have all columns
  @for $i from 1 through 7 {
    &--#{$i}-columns {
      .dm2020-aoc-group-table__table {
        // 110px to 200px width per column
        min-width: ($i + 1) * 110px;
        max-width: ($i + 1) * 200px;
      }
    }
  }

  @media (min-width: 1200px) {
    &__cg-photo {
      width: 80%;
      height: auto;
      background-color: lightblue;
    }
  }

  .cg-flex-container {
    display: flex;
    flex-direction: column;
    height:100%;
  }

  .cg-flex-container > div {
    margin:0;
  }

  .cg-flex-container > .cg-reflection-link {
    margin-top: auto;
  }
}

// New DM, guided view
.dm2020-guided {
  &__previous-selection { color: #ccc }

  &__judgement-decision {
    font-size: 36px;

    img {
      width: 150px;
      height: auto;
      float: left;
      margin-right: 15px;

      @media (max-width: 767px) {
        width: 80px;
      }
    }
  }

  &__select-order {
    white-space: normal;
    padding: 20px;
  }

  &__progress-bar-text {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  &__add-reflection {
    @each $judgement, $colour in $dm2020-judgements-colour-palette {
      &-#{$judgement} {
        border-color: $colour;
        > .panel-heading {
          background: lighten($colour, 25%);
          color: darken($colour, 25%);
        }
      }
    }
  }
}

.dm2020-timeline {
  &__row {
    margin: 0;
    padding: 10px 0 25px;
  }

  &__date {
    text-align: center;
  }

  &__description {
    background: #F5F5F5;
    padding: 10px;
    border-radius: 5px;
  }

  &__judgement {
    text-align: center;
    padding: 0 0 0 15px;
  }

  &__judgement-icon {
    height: 40px;
    width: 40px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;

    @each $judgement, $colour in $dm2020-judgements-colour-palette {
      &--#{$judgement} {
        @extend .dm2020-timeline__judgement-icon;
        background-color: #{$colour};
      }
    }
  }
}

// Flags Thoroughness
@import "./reflection_tags.scss";

.flag-thoroughness-table {
  width: 100%;
  overflow-x: auto;

  &__row {
    display: inline-flex;
    min-width: 100%;
    border-top: 1px solid #ddd;
  }

  &__column-header {
    font-weight: bold;
  }

  &__header {
    flex-shrink: 0;
    flex-grow: 1;
    width: 200px;
    border-right: 1px solid #ddd;
    padding: 10px;
    background-color: white;

    @media (min-width: 768px) {
      position: sticky;
      left: 0;
    }

    @media (min-width: 992px) {
      width: 300px;
    }
  }

  @for $i from 1 to 5 {
    &__header--indent-#{$i} {
      padding-left: #{($i * 20) + 10}px;
    }
  }

  &__flag-colour {
    flex: 0 0 20px;
    @include reflection-tag-foregrounds;
  }

  &__flag-name {
    font-weight: bold;
  }

  &__cell {
    flex-shrink: 0;
    width: 200px;
    padding: 10px;
  }

  &__cell--linked:hover {
    background-color: #ddd;
  }
}

[data-filter-section="framework"] select.form-control {
  width: 100%;
}

// Reuse flag-thoroughness-table for Bespoke Framework Thoroughness
.framework-thoroughness-inner {
  .flag-thoroughness-table {
    margin: 0 0 8px 0;

    &__column-header {
      display: flex;
      justify-content: center;
      align-items: center;

      &--scales {
        flex: 1;
      }
    }

    &__header {
      flex-shrink: 0;
      flex-grow: 1;
      width: 300px;
      box-sizing: border-box;
    }

    &__header:first-of-type {
      max-width: 300px;
    }

    &__cell {
      min-width: 104px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-wrap: anywhere;
      hyphens: auto;
    }

    &__cell--bx {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }

    &__cell--br {
      border-right: 1px solid #ddd;
    }

    &__cell--disabled {
      background-color: #f5f5f5;
      padding: 10px 4px;
    }

    @for $i from 1 to 27 {
      &__cell--colspan-#{$i} {
        width: #{$i * 104}px;
      }
    }

    @for $i from 1 to 6 {
      &__cell--num-equal-width-#{$i} {
        width: #{(800 - 12) / $i}px;
      }
    }
  }
}

.framework-thoroughness-column {
  &--alt-bg {
    background-color: #006fbb14;
  }
}

.framework-thoroughness-contributing-obs {
  h3 {
    font-size: 1.25em;
    margin: 20px 0 5px 0;
  }

  .bespoke-display__text {
    font-size: 1.15em;
  }
}

/* Prevents Chrome from automatically hiding the scrollbars */
.framework-thoroughness-inner {
  .doubleScroll-scroll-wrapper {
    margin: 4px 0;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
    padding: 4px 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.btn-framework-thoroughness-paginate {
  width: 100%;
  margin: 0 0 8px 0;
}

.framework-visible-columns {
  &__column {
    display: inline-block;
    margin-right: 16px;
  }

  &__tooltip {
    margin: 0 0 6px 0;
    color: #3585c4;
  }
}

.framework-period-form__checkbox-group {
  &.form-group {
    display: block;
  }
}

// Scrollable Table
.flag-thoroughness-scrollable-table {
  $border-color: #ddd;
  $scales-key-height: 75px;
  $scrollable-height: 75vh;

  &.full-page {
    height: $scrollable-height;
    overflow: auto;
  }

  .alt-bg {
    background-color: #006fbb14;
  }

  .cell-disabled {
    background-color: #f5f5f5;
  }

  .flag-name {
    font-weight: bold;
  }

  .text-middle {
    vertical-align: middle;
  }

  table {
    border-collapse: separate;
    border-bottom: 2px solid $border-color !important;
    border-right: 2px solid $border-color !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }

  thead.has-scales {
    top: $scales-key-height;
  }

  th {
    border-top: 1px solid $border-color !important;
    border-bottom: none !important;
  }

  thead > tr:first-child > th {
    border-top: 2px solid $border-color !important;
  }

  thead > tr:last-child > th {
    border-bottom: 2px solid $border-color !important;
  }

  #scales-key {
    position: sticky;
    top:0;
    left:0;
    background-color: white;
    z-index: 3;
    width: 100%;
    height: $scales-key-height;
  }

  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    min-width: 300px;
    max-width: 300px;

    @media (max-width: 767px) {
      min-width: 100px;
    }
  }

  .bordered-cell {
    border-left: 1px solid $border-color !important;
  }

  .row-data {
    z-index: -1;
  }

  .left-corner {
    border-top: 0;
    border-bottom: 0;
  }

  .no-x-borders {
    border-right: none;
    border-left: none;
  }

  .no-right-border {
    border-right: none;
  }

  .no-left-border {
    border-left: none !important;
  }

  .borders-x {
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }

  .table > thead > tr > th:first-child,
  .table > tbody > tr > td:first-child {
    border-left: 1px solid white;
    border-right: 2px solid $border-color;
  }

  .pointer {
    cursor: pointer;
  }
}
