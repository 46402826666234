.fat-finger {
  font-size: 1.5em;
  padding: 0 15px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.tooltip-wide + .tooltip > .tooltip-inner {
  width: 500px;
}

.horizontal-grouping {
  white-space: nowrap;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.vertical-align-end {
  display: flex;
  align-items: flex-end;
}

.helper-cursor {
  cursor: help
}

.row.match-height {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  > [class*='col-'] {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }
  &:after, &:before { display: flex }
}

.fit-to-container {
  object-fit: contain;
  width: 100%;
}
