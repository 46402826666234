.password-reset-link-container {
  margin-top: 10px;
  border: 1px solid rgb(0, 0, 0);
  padding: 10px;
}

.key-children-dropdown {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-labels {
  .label {
    line-height: 2;
  }
}

.manager-parental-responsibility-wrapper {
  & label {
    font-weight: normal;
  }
}

.remove-child-assignment-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}

.permission-highlight {
  border: 1px solid #68b3ff;
  box-shadow: 0 0 0  3px #dde9ff;
}
