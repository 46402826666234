.media-upload-actions {
  @media (max-width: 480px) {
    & button {
      width: 200px;
      display: block;
      margin: 0 auto 10px auto;

      &:first-child {
        margin: 10px auto 10px auto;
      }

      &:last-child {
        margin: 0 auto;
      }
    }
  }

  @media(min-width: 481px) {
    .media-upload-actions__delete {
      float: right;
    }
  }
}

.media-upload-actions-child {
  @media (max-width: 1000px) {
    .btn-child {
      max-width: 100%;

      button {
        max-width: 100%;
      }
    }
  }

  @media(min-width: 1001px) {
    .media-upload-actions__delete {
      float: right;
    }
  }
}

.manage-media-action {
  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
