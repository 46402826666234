.text-diff-changes {

}

.text-diff-changes__line {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 2px 2em 2px 2px;
}

.text-diff-changes__line:first-child {
  border-top-width: 1px;
}

.text-diff-changes__line:after {
  content: "⤶";
  font-size: 1.2em;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0.5em;
}

.text-diff-changes__line--del-break:after {
  background-color: #F2DEDE;
  color: #a94442;
}

.text-diff-changes__line--ins-break:after {
  background-color: #DFF0D8;
  color: #3c763d;
}

.text-diff-changes__line--no-break:after {
  display: none;
}

.text-diff-changes__line--empty:before {
  content: " ";
  white-space: pre;
}

.text-diff-changes__change--del {
  background-color: #F2DEDE;
  text-decoration: underline dashed #a94442;
}

.text-diff-changes__change--ins {
  background-color: #DFF0D8;
  text-decoration: underline dashed #3c763d;
}

.text-diff-legend {

}

.text-diff-legend__item {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 5px;
}

.text-diff-legend__colour--del {
  color: #F2DEDE;
}

.text-diff-legend__colour--ins {
  color: #DFF0D8;
}

.text-diff-legend__break::after {
  content: "⤶";
}
