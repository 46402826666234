.page-link-editor {
  .panel-footer {
    padding-top: 5px;

    .btn {
      margin-top: 5px;
    }
  }
}

@mixin page_status_label_text_overflow() {
  .reflection-status-label, .obs-status-label {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }
}

.page-link-list-item {

  .media-left {
    @include page_status_label_text_overflow();
  }

  .page-link-list-item-modal-buttons {
    display: inline;

    .btn {
      border-color: transparent;
    }
  }

  .page-link-list-item-linked-list-buttons {
    display: none;
  }

  [data-action="link_page"] {
    display: inline;
  }

  [data-action="unlink_page"] {
    display: none;
  }

  &.linked {
    [data-action="link_page"] {
      display: none;
    }

    [data-action="unlink_page"] {
      display: inline;
    }
  }

  &.obs-item {
    .page-link-observation-item-assessment-count-buttons {
      display: none;
    }
    .page-link-observation-item-assessment-count-static {
      display: inline;
    }
  }

  .page-link-flag-count-buttons {
    display: none;
  }
  .page-link-flag-count-static {
    display: inline;
  }
}

.page-link-linked-list {
  margin-bottom: 0;

  .page-linked-list-parent-page {
    background-color: #00B050;
    color: white;
    text-align: center;
    font-size: 1.2em;
    padding: 5px;
  }

  .page-link-linked-list-help {
    font-size: 1.2em;
    text-align: center;
    padding: 5px;

    color: #333333;
    background-color: #f5f5f5;

    border: 1px solid #dddddd;
    border-right-width: 0;

    .page-link-linked-list-help-inner {
      width: 60px;
    }
  }

  .page-link-linked-list-pages-wrapper {
    .list-group {
      margin: 0;
    }
  }

  .page-link-linked-list-before {
    .page-link-linked-list-help {
      vertical-align: bottom;
      border-top-left-radius: 3px;
      border-bottom-width: 0;
    }

    .list-group-item {
      &:first-child {
        border-top-left-radius: unset;
      }
      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      }
    }
  }

  .page-link-linked-list-after {
    .page-link-linked-list-help {
      border-bottom-left-radius: 3px;
      border-top-width: 0;
    }

    .list-group-item {
      &:first-child {
        border-top: none;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }
      &:last-child {
        border-bottom-left-radius: unset;
      }
    }
  }

  .page-link-list-item {

    .media-body .row {
      margin-right: 0;
    }

    .page-link-list-item-modal-buttons {
      display: none;
    }

    .page-link-list-item-linked-list-buttons {
      display: inline;
    }

    [data-action="link_page"] {
      display: none;
    }

    [data-action="unlink_page"] {
      display: inline;
    }

    .page-link-list-item-buttons {
      padding-left: 0;
      padding-right: 0;
    }

    &.obs-item {
      .page-link-observation-item-assessment-count-buttons {
        display: inline;
      }
      .page-link-observation-item-assessment-count-static {
        display: none;
      }
    }

    .page-link-flag-count-buttons {
      display: inline;
    }
    .page-link-flag-count-static {
      display: none;
    }
  }
}

.page-link-bar {

  &.anchor-fix::before {
    content: '';
    display: block;
    height:      120px;
    margin-top: -120px;
    visibility: hidden;
  }

  .well-sm {
    margin-bottom: 10px;
  }

  .page-link-bar-label-container {
    font-size: 1.2em;

    .page-link-bar-label {
      padding: 5px 10px;
      margin-left: 5px;
      display: inline-block;
      border-radius: 5px;
      background-color: #337ab7;
      color: white;
      cursor: help;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .page-link-bar-description-container {
    text-align: center;
    vertical-align: middle;
    font-size: 1.2em;
    line-height: 34px;
  }

  .page-link-bar-toggle-list-button {
    [data-show-icon] {
      display: inline-block;
    }
    [data-hide-icon] {
      display: none;
    }

    &.active {
      [data-show-icon] {
        display: none;
      }
      [data-hide-icon] {
        display: inline-block;
      }
    }
  }
}

.page-link-view-list {
  .page-link-linked-list-pages-wrapper {
    border: 1px solid #dddddd;
    padding: 10px 10px 0;
  }

  .page-link-linked-list-before {
    .page-link-linked-list-pages-wrapper {
      border-top-right-radius: 3px;
      border-bottom-width: 0;
    }
  }

  .page-link-linked-list-after {
    .page-link-linked-list-pages-wrapper {
      border-bottom-right-radius: 3px;
      border-top-width: 0;
    }
  }

  .observation-item {
    .media-left {
      padding-right: 10px;

      @media (min-width: 768px) {
        max-width: 105px;
      }

      max-width: 55px;

      @include page_status_label_text_overflow();
    }
  }

  .reflection-item {
    .media-left {
      min-width: initial;
      overflow: hidden;
    }
  }
}

.page-link-list-item-page-type-icon {
  font-size: 34px;
  vertical-align: middle;
  color: #70B7FF;
}
