/* Switch user form */
#switchUserForm {
  max-width: 480px;
  margin: auto;
}

#switchUserForm .logo {
  background: #fff;
  padding: 3px;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  display: block;
  margin: 10px auto 20px auto;
  width: 100%;
}

#switchUserForm .logInRow.submit { text-align: center; margin-left: 0px }
