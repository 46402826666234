$level-below: rgb(249, 222, 229);
$level-met: rgb(207, 249, 213);
$level-above: rgb(211, 226, 249);
$level-na: rgb(255, 233, 130);

.snapshot-legend {
  padding: 6px;
  display: inline-block;
}

.snapshot-legend-item {
  white-space: nowrap;
  margin-bottom: 2px;
}

.snapshot-header {
  background-color: gainsboro;
  padding: 0px 0 0px 5px;
}

.snapshot-group-eyfs-aspect {
  min-width: 50px;
}

.area-row {
  background-color: #444444;
  color: #ffffff;
  font-size: 1.4em;
  padding: 5px;
  font-weight: normal;

  &.primary {
    background-color: #337ab7;
  }
}

@mixin level-decoration {
  &.level-low {
    background: $level-below;
    border-bottom: 1px solid darken($level-below, 10);
    color: darken($level-below, 68);
  }

  &.level-medium {
    background: $level-met;
    border-bottom: 1px solid darken($level-met, 10);
    color: darken($level-met, 64);
  }

  &.level-high, &.level-assessed {
    background: $level-above;
    border-bottom: 1px solid darken($level-above, 10);
    color: darken($level-above, 68);
  }

  &.level-na {
    background: $level-na;
    border-bottom: 1px solid darken($level-na, 10);
    color: darken($level-na, 68);
  }
}

.aspect-row {
  padding: 10px 0;
  border-bottom: 1px solid #d6d6d6;

  @include level-decoration;
}

.aspect-div {
  padding: 10px;
  border-bottom: 1px solid #d6d6d6;

  @include level-decoration;
}

.category-with-assessments {
  div.checkbox {
    margin: 6px 0 0 0;
  }
}

.simple-snapshot-cell {
  &.level-low {
    background: $level-below;
    color: darken($level-below, 64);
  }

  &.level-medium {
    background: $level-met;
    color: darken($level-met, 64);
  }

  &.level-high, &.level-assessed {
    background: $level-above;
    color: darken($level-above, 70);
  }

  &.level-na {
    background: $level-na;
    color: darken($level-na, 70);
  }
}

.ks-baseline-cell {
  border-radius: .25em;
}

.group-snapshot-left {
  width: 150px;
  float: left;

  &.wider {
    width: 330px;
  }

  .group-snapshot-child-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.group-snapshot-right {
  padding-left: 150px;

  &.wider {
    padding-left: 330px;
  }
}

.ks-group-snapshot-left, .mi-group-snapshot-left, .adm-group-snapshot-left {
  position: absolute;
  width: 330px;
}

.ks-group-snapshot-right, .mi-group-snapshot-right, .adm-group-snapshot-right {
  td {
    white-space: nowrap;
  }
}

.ks-group-snapshot-statement, .adm-group-snapshot-statement {
  width: 330px;
  word-wrap: break-word;
  white-space: normal !important;

  &.hidden {
    width: 334px;
  }

  .pad {
    margin-left: 10px;
  }
}

.ks-group-snapshot-cell, .mi-group-snapshot-cell {
  text-align: left !important;
}

.ks-group-snapshot-baseline-tier {
  padding: 1px;
}

.ks-group-snapshot-tier, .mi-group-snapshot-refinement {
  padding: 1px;
}

.mi-group-snapshot-row-heading {
  width: 326px;
  word-wrap: break-word;
  white-space: normal !important;

  &.hidden {
    width: 334px;
  }

  .pad {
    margin-left: 10px;
  }

  .mi-extension {
    font-style: italic;
    padding-left: 20px;
  }
}

.mi-snapshot-activity-container {
  border: 1px solid #396788;
  margin: 5px -15px 5px -15px;

  .row {
    margin-left: 0;
    margin-right: 0;
    padding-right: 5px;
  }

  .mi-snapshot-activity-heading {
    background-color: #396788;
    color: #ffffff;
    font-size: 16px;
    padding: 5px;
    font-weight: normal;
  }
}

.group-snapshot {
  td {
    border-bottom: 1px solid #dddddd;
    white-space: nowrap;
  }
}

.nowrap {
  white-space: nowrap;
}

.group-snapshot-td {
  text-align: center;
  cursor: pointer;

  &.level-low {
    background: $level-below;
    color: darken($level-below, 64);
  }

  &.level-medium {
    background: $level-met;
    color: darken($level-met, 64);
  }

  &.level-high, &.level-assessed {
    background: $level-above;
    color: darken($level-above, 70);
  }

  &.level-na {
    background: $level-na;
    color: darken($level-na, 70);
  }

  .refinement {
    font-weight: bold;
  }
}

.fa {
  &.level-low {
    color: darken($level-below, 10);
  }

  &.level-medium {
    color: darken($level-met, 10);
  }

  &.level-high, &.level-assessed {
    color: darken($level-above, 10);
  }

  &.level-na {
    color: darken($level-na, 10);
  }
}

.snapshot-overridden {
  margin-top: 2px;
  font-size: 16px;
  float: left;

  background: rgba(255, 255, 255, 0.60);
  border-radius: 4px;
  padding: 7px;

  color: #393939;

  @media (min-width: 1200px) {
    margin-left: 7px;
  }
}

.snapshot-overridden--cherry-garden-baseline {
  @extend .snapshot-overridden;

  border-radius: 0;
  float:none;
  position: absolute;
  top: -2px; //0 still leaves some space?
  right: 0;
}

.aspect-row-text {
  font-size: 1.1em;
  margin-top: 7px;
}

.assessment-fields {
}

.assessment-field {
  margin-right: 5px;
}

.ks-formative-req-group {
  margin-left: 20px;
}

.ks-baseline-eyfsp-score {
  text-align: center;
  cursor: default;
  margin: 0;
  padding: 5px;

  &.level-low {
    background: $level-below;
    color: darken($level-below, 64);
  }

  &.level-medium {
    background: $level-met;
    color: darken($level-met, 64);
  }

  &.level-high, &.level-assessed {
    background: $level-above;
    color: darken($level-above, 70);
  }
}

.label-adm {
  border: 1px solid #777;
  background-color: white;
  color: #333;

  &.level-na {
    background-color: $level-na;
  }

  &.level-low {
    background-color: $level-below;
  }

  &.level-medium {
    background-color: $level-met;
  }

  &.level-high {
    background-color: $level-above;
  }
}

// AGE BAND TRACKER //

/* Progress map table */
.progress_map_table {
  margin-bottom: 10px;
}
.progress_map_table th,
.progress_map_table td {
  min-width: 6em;
  border:1.5px solid #7a7a7a;
  vertical-align: bottom;
}
.progress_map_table td p {
  margin-bottom: 12px;
}
.progress_map_table td p.percent_box {
  width: 100%;
  text-align: center;
  font-style: italic;
  margin-top: 12px;
  margin-bottom: 2px;
}
.progress_map_table td:first {
  min-width: 12em;
}

.group_by_band_table td.hover, .group_by_band_table tr:hover td {
  background-color: #eee;
}

.group_by_band_table td.emptyBand {
  border-top: none;
}

.group_by_band_table tr:hover td.emptyBand {
  background-color: #fff;
}

.group_by_band_table td {
  min-width: 7em;
}

.group_by_band_table .child {
  display: block;
  color: #000;
}

.group_by_band_table.highlight .child,
.group_by_band_table.highlight .percent {
  color: #eee;
}

.group_by_band_table .child.selected,
.group_by_band_table .percent.selected {
  color: #000;
}

.group_by_band_table th {
  font-weight: bold;
  text-align: center;
}

.group_by_band_table td {
  border-top: dotted 1px lightgrey;
}

.group_by_band_table td.newBand, .group_by_band_table td.newBand ~ td {
  border-top: solid 1px darkgrey;
}

.group_by_band_table td.newArea, .group_by_band_table td.newArea ~ td {
  border-top: solid 2px black;
}

.group_by_band_table td.groupofchildren {
  text-align: center;
  vertical-align: middle;
}

/* The following are used to freeze the first column in the table */
table.group_by_band_table {
  border-collapse: separate;
  direction: ltr; /* Back to default, from reversed direction in group_by_band_table_inner_wrapper */
}

.group_by_band_table .newBand, .group_by_band_table .emptyBand {
  width: 4em;
  min-width: 0;
  position: absolute;
  top: auto;
  left: 0em;
  padding: 0;
  font-weight: bold;
}

.group_by_band_table .newRefinement {
  width: 6em;
  min-width: 0;
  position: absolute;
  top: auto;
  left: 4em;
  padding: 0;
  font-weight: bold;
}

.group_by_band_table_inner_wrapper {
  overflow-x: auto;
  margin-left: 10em;
}

.group_by_band_table_inner_wrapper_no_refinements {
  overflow-x: scroll;
  margin-left: 4em;
}

.group_by_band_table_outer_wrapper {
  position: relative;
  margin-bottom: 40px;
}

h2.areaTitle {
  font-size: 20px;
  font-weight: bold;
  color: #198513;
  padding-bottom: 5px
}

th.age-band-tracker-period {
  padding: 0 5px;
}

.snapshot-estimation-strategy.list-group-item {
  overflow: auto;
  cursor: pointer;
}

/* Change estimation strategy form */
.snapshots-estimation-strategy-left {
  width: 95%;
  float: left;
}

// STATEMENTS TICKED //

.group-statements-left {
  position: absolute;
  width: 330px;
}

.group-statements-statement {
  width: 330px;
  overflow: hidden;
  word-wrap: break-word;

  &.wider {
    width: 800px;
  }
}

.group-statements-last-obs {
  width: 300px;
}

.hidden-statement {
  width: 314px;
}

.scroll-button-container {
  position: absolute;
  display: none;
}

.scroll-button-left {
  position: fixed;
  top: 50%;
  z-index: 30000;
  margin-left: -40px;
  display: none;
}

.scroll-button-right {
  position: fixed;
  top: 50%;
  z-index: 30000;
  margin-right: -40px;
  display: none;
}

@media (min-width: 992px) {
  .scroll-button-left {
    display: inline;
  }

  .scroll-button-right {
    margin-left: 893px;
    display: inline;
  }
}

@media (min-width: 1200px) {
  .scroll-button-left {
    display: inline;
  }

  .scroll-button-right {
    margin-left: 1097px;
    display: inline;
  }
}

.group-statements-right {
  .no-vertical-border {
    border-top: none !important;
    border-bottom: none !important;
  }

  .white-space-normal {
    white-space: normal !important;
  }

  .fixed-width-wrap {
    display: block;
    max-width: 312px;
    overflow: hidden;
    word-wrap: break-word;
  }
}

// POINTS PROGRESS //
.points-progress-wrapper {
  overflow-x: auto;
}

.point-progress-td {
  width: 300px;
  white-space: nowrap;
}

// SIMS EXPORT //

table.sims_export {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

table.sims_export td, table.sims_export th {
    border: 1px solid black;
    padding: 3px;
} 
