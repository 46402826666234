@import "bespoke_flag_sets/colour-radio";
@import "bespoke_flag_sets/category-display-picker";
@import "bespoke_flag_sets/review";
@import "bespoke_flag_sets/colours";

// ADD/EDIT FORM

.bespoke-flag-set-form {
  &__submission {
    text-align: right;
  }

  &__description {
    resize: vertical;
    min-height: 55px;
  }

  &__note {
    padding-top: 5px;
    font-size: 0.9em;
  }
}

// EDITED BADGES

.bespoke-flag-set-editor-panel > .panel-heading .badge, .badge-edited {
  background-color:  #28A745;
}

// MOVE MODAL
.bespoke-flag-set-move-modal {
  &__move-placeholder {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid blue;
    width: 125px;
    cursor: pointer;
    color: blue;
    text-align: center;
  }

  &__move-button {
    white-space: normal;
  }
}

.flag-scale-set-item-count {
  width: 80px;
  display: inline-block;
}

.drafts-sub-heading {
  margin-top: -15px;
}

.scroll-if-needed {
  overflow-x: auto;
  padding-bottom: 175px;
}

.disabled-during-update {
  pointer-events: none;
  opacity:0.7;
}

// Configuration modal
.bespoke-flag-configuration-panel {
  .panel-body div:first-child h3:first-child {
    margin-top: 0px;
  }
}

.badge-edited {
  display: inline-block;
}

.sortable-list, .bespoke-flag-static-list {
  margin-left: 20px; /* For the nice "drag child left of self to put on par with parent level jQuery UI style drag */

  .draggable-placeholder {
    padding: 5px;
    border-radius: 5px;
    border: 10px solid pink;
  }

  ol.with-treelines > li:last-child.draggable-placeholder {
    border-left: 10px solid pink;
  }

  .draggable-selected::before {
    /*content: "  ❏ ";*/
    font-size: 3em;
    color: rgba(30, 30, 30, 0.8)
  }

  .draggable-selected .bespoke-flag-dropdown, .draggable-selected .handle, .draggable-selected span {
    opacity: 0;
    vertical-align: middle;
    visibility: hidden;
  }

  .draggable-selected li, li.draggable-selected, li.draggable-selected:before {
    border: 0 !important;
  }

  .draggable-selected ol.with-treelines > li, .draggable-selected ol.with-treelines > li:before {
    border: 0 !important;
  }

  .draggable-possible-next-position {
    background-color: transparent;
  }

  .bespoke-flag {
    font-size: 1.8rem;
    /*background-color: gold; for demo'ing bboxs */
    border-radius: 6px;
    margin-bottom: 5px;
    padding-right: 5px;

  }
  .handle {
    color: white;
    cursor: move;
    margin-right:5px;
    padding: 4px 4px 5px;
    z-index:10;
    vertical-align: top;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .bespoke-flag-dropdown {
    margin-top: 4px;
    align-self: flex-start;
  }

  .bespoke-flag-deleted .handle {
    background-color: lightgrey;
    cursor: not-allowed!important;
  }

  .bespoke-flag-deleted {
    background-color:rgba(220,220,220,0.7); /* For lower opacity without impacting child menu/cog etc */
    border-color: lightgrey;
  }

  .bespoke-flag-connect-with-treelines {
    margin-left:-14px;
    /*line-height: 1.25; */
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    box-shadow: 3px 3px 3px lightgrey;
  }

  .bespoke-flag-name {
    padding: 5px;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    line-height: 130%;
    flex-shrink: 1;
  }

  .bespoke-flag-name-text {
    overflow-wrap: anywhere;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .bespoke-flag-name-toggle {
    font-size: 12px;
  }

  .bespoke-flag-set--root { /* Quickfix, sometimes the flag root does not have a colour and empty string leads to this class after edits... */
    border: 1px solid lightgrey;
  }

  .bespoke-flag-category-name {
    font-size: 12px;
    color: #999;
  }

}

.sortable-list > li {
  display: table; // Size according to contents, not parent
  white-space: nowrap; // Do not let contents wrap
  list-style: none !important;
  margin-top: 0px !important;
  padding-top: 8px !important;
  margin-left: 0px;
}

.sortable-list:not(:empty) {
  padding-bottom: 3px;
}

.bespoke-flag-static {
  padding: 6px;
  margin-bottom: 0.8em; /* align with treelines */
  border-radius: 10px;
}

.bespoke-flag-static--highlighted {
  border: 3px solid black;
  margin-left:-15px; /* align with other flags despite padding */
}

.bespoke-flag-static--highlighted-deleted, .bespoke-flags-modal-message {
  opacity: 0.8;
  background-color:lightgrey;
  padding: 5px;
  border-radius: 5px;
}

.bespoke-flag-static-list {
  list-style: none!important;
  margin-top:10px;
}

.bespoke-flag-static-list > li {
  display: table; // Size according to contents, not parent
  white-space: nowrap; // Do not let contents wrap
}

.bespoke-flags-view-mode-selected {
  box-sizing: border-box;
}

.bespoke-flag-structure-option-button {
  /* margin: 8px; To make it work with treelines and not lose vertical space causing overflow issues */
  margin-bottom:6px!important;
}

.bespoke-flag-category-type {
  font-size: 12px;
  color: #999;
}

ol.with-treelines > li:first-child:before {
  content: '';
  display: inline-block;
  min-height: 30px; /* todo: Stop the treelines overtaking the flag (zIndex isn't working easily) */
  top: -0.8em;
}

/* From: https://jsfiddle.net/1fynun7a/1/ */
ol.with-treelines {
  list-style: none;
  margin-top: 5px;
  padding: 0;
  padding-top: 4px;
}

ol ol.with-treelines {
  margin-top: 1px; /* if nested, attach treeline to the flag */
  padding-bottom: 6px; /* Allow drag into bottom of list */
}

ol.with-treelines:empty {
  padding-bottom: 25px;
}

ol.with-treelines > li > ol {
  margin-left: 20px; /* This is coded elsewhere in Tapestry */
}

ol.with-treelines > li {
  padding: 0 0 0 10px;
  line-height: 20px;
  border-left: 1px solid rgb(100, 100, 100);
}

ol.with-treelines > li:last-child {
  border-left: 0px solid white;
}

ol.with-treelines > li:before {
  position: relative;
  top: -0.3em;
  height: 1.2em;
  width: 12px;
  color: white;
  border-bottom: 1px solid rgb(100, 100, 100);
  content: "";
  display: inline-block;
  left: -10px;
  vertical-align: top;
}

ol.with-treelines > li {
  margin-top: -10px !important; /* Avoid Tree line gap */
}

ol.with-treelines > li:only-child {
  margin-top: -7px !important; /* If only one element, slightly different offset */
}

ol.with-treelines > li:last-child:before {
  border-left: 1px solid rgb(100, 100, 100);
}

#flag-set-editor-root {
  display: table; // Size according to contents, not parent
  padding-right: 15px; // Space between flag and edge of panel
}

#flag-set-editor-root-flag {
  // Size according to contents, not parent
  display: inline-block;
  width: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Settings */
.bespoke-flag-settings-list-group {
  margin-bottom: 0;

  .list-group-item {
    border: none;
    border-bottom: 2px solid #dddddd!important;

    &:last-child {
      border: none!important;
    }
  }
}

[aria-expanded="false"] > .expanded,
[aria-expanded="true"] > .collapsed {
  display: none;
}

.bespoke-framework-actions {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  &__action {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  &__title {
    font-size: 1.15em;
    margin-top: 0;
  }
  &__button {
    margin-top: auto;
  }
}

.bespoke-framework-preview {
  &__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__button {
    align-self: flex-end;
  }
  &__title {
    font-size: 1.15em;
    margin-top: 0;
    margin-bottom: 0;
  }
}
