.feedback-wrapper {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1000;

  .js-feedback-alert {
    border-radius: 0;
    padding: 8px;
  }
}

body[data-admin] {
  .feedback-wrapper {
    margin-top: 51px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
