.preferences-section {
  float:left;
  width:100%;
  p.preferences-section-last-child {
    display:inline;
  }
}

.spacing-top-lg {
  margin-top:50px;
}

.preferences-right-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 138px;
}

.preferences-right-rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:100%;
}
