/* VIEW OBS */

.obs-page-top {
  overflow: auto;
  margin-bottom: 15px;

  .pagination {
    margin: 0px;
  }
}

#carouselButtons {
  margin-top: 20px;
}

.carousel-inner > .item > img {
  margin: 0 auto;
}

.obs-uploaded-media {
  &[data-media-id="0"] {
    display: none;
  }

  .media-left {
    img {
      max-width: 80px;
    }
  }
}

.page-note {
  text-align: left;
  position:relative;
  padding:0.7em 1.5em;
  color:#000;
  background:#fefabc;
}

.page-note .n {
  font-size: 18px;
  padding: 25px 0px;

  word-wrap: break-word;
}

/* Wrap the video in this to maintain aspect ratio responsively */
.obs-video-wrapper {
  width: 100%;
  /*padding-bottom: 56.25%;*/ /* 16:9 aspect ratio */
  padding-bottom: 75%; /* 4:3 aspect ratio */
  position: relative;

  /* Video fills available space in wrapper */
  .obs-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.observation-view-toggle-linked-reflections-button {
  [data-show-icon] {
    display: none;
  }
  [data-hide-icon] {
    display: inline-block;
  }

  &.collapsed {
    [data-show-icon] {
      display: inline-block;
    }
    [data-hide-icon] {
      display: none;
    }
  }
}

/* BROWSE OBS */

.observation-item {
  background-color: #ffffff;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);

  padding: 10px;

  overflow: visible;

  margin-bottom: 15px;

  border-left: 5px solid white;

  .media-left {
    img {
      @media (min-width: 768px) {
        max-width: 95px;
        max-height: 95px;
      }

      max-width: 45px;
      max-height: 45px;

      margin-bottom: 5px;
    }
    .obs-status:not(.collapse) {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .children-tags {
    margin-top: 10px;
    overflow: visible;
    display: inline-block;

    & .child-tag {
      white-space: unset;
    }
  }

  .short-notes {
    padding-top: 10px;
    display: none;

    word-wrap: break-word;

    @media (min-width: 768px) {
      display: inherit;
      width: 400px;
    }

    @media (min-width: 992px) {
      width: 530px;
    }

    @media (min-width: 1200px) {
      width: 690px;
    }
  }

  .observation-item-buttons {
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    input {
      margin-top: 8px;
    }
  }

  &.status-needsapproval, &.status-fromparent {
    border-left: 5px solid #f0ad4e;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
    background: #F0D199; /* Old browsers */
    background: -moz-linear-gradient(left,  #F0D199 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F0D199), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
    background: linear-gradient(to right,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F0D199', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  }

  &.status-hidden {
    border-left: 5px solid red;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
    background: #f9c7c7; /* Old browsers */
    background: -moz-linear-gradient(left,  #f9c7c7 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#f9c7c7), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
    background: linear-gradient(to right,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9c7c7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  }

  &.status-noparent, &.status-scheduled {
    border-left: 5px solid #5fc4fc;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9f4fc+0,ffffff+5,ffffff+5,ffffff+36 */
    background: #c9f4fc; /* Old browsers */
    background: -moz-linear-gradient(left,  #c9f4fc 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9f4fc', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  }

  .media-left {
    padding-right: 0;
  }
}

/* MANAGE OBS */

#uploader {
  border-left: 1px solid #dddddd;
}

.obs-not-in-journal-alert {
  margin-top: 5px;
}

.obs-media-upload-queue {
  .obs-media-upload-file-thumbnail {
    max-height: 100px;
  }

  .obs-media-upload-file-thumbnail-overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-left: 15px;
      margin-right: 15px;
      opacity: 0.7;
      background-color: white;
      display: none;
      text-align: center;
      cursor: help;

      i {
          display: inline-block;
          vertical-align: middle;
      }
  }

  .obs-media-upload-file-thumbnail-overlay::before {
      content: " ";
      display: inline-block;
      height: 100%;
      width: 1px;
      vertical-align: middle;
  }

  .obs-media-upload-file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .obs-media-upload-error-message {
      margin-top: 15px;
  }

  .obs-media-upload-file-label {
      cursor: help;
  }
}

.obs-media-uploader-child {
  .obs-media-upload-file-thumbnail {
    max-height: none;
    max-width: 100%;
    height: auto;
  }
}

.obs-media-download {

  a {
    display: inline-block;
    position: relative;

    img {
      margin-bottom: 5px;
    }

    i {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      line-height: 90px;
      background-color: #000;
      color: #fff;
      opacity: 0;

      &:hover {
        opacity: 0.7;
      }
    }
  }

}

.deleteComment, .editComment {
  margin-left: 10px;
}

.obs-media-gallery .button-prev-container {
  float: left;
  position: absolute;
  margin-top: 50px;
  z-index: 50;
}

.button-prev {
  background: #000;
  background: rgba(0, 0, 0, 0.68);
  color: #fff;
  padding: 4px 8px 4px 55px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.obs-media-gallery .button-next-container {
  float: right;
  position: absolute;
  margin-top: 50px;
  right: 20px;
  z-index: 50;
}

.button-next {
  background: #000;
  background: rgba(0, 0, 0, 0.68);
  color: #fff;
  padding: 4px 55px 4px 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.obs-media-gallery-main {
  cursor: pointer;
}

.obs-metadata {
  & p:not(:last-of-type) {
    margin: 0;
  }
}

#hasAssessments {
  display: none;
}

.load-picker-buttons {
  button {
    margin-bottom: 10px;
  }
}

// Hidden area for uploaded images on android to work around issue #2911
#canvas-area {
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.observation-relative {
  position: relative;
}

.observation-absolute {
  position: absolute;
}

.observation-link {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: -5px;
}

.activity-btn {
  z-index: 1;
}

.observation-frame:hover {
  -webkit-box-shadow: 0px 0px 7px 0px #62a8e9;
  -moz-box-shadow: 0px 0px 7px 0px #62a8e9;
  box-shadow: 0px 0px 7px 0px #62a8e9;

  .media-heading {
    color: #337ab7;
    text-decoration: underline;
  }
}

//Booking banner//

@font-face {
  font-family: 'Lato';
  src: url('/fonts/google/Lato-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('/fonts/google/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Abel';
  src: url('/fonts/google/Abel-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

#banner-outer {
  border-radius: 20px;
  border: 3px solid #A03592;
  padding: 20px 20px 20px 20px;
  width: auto;
  max-width: 100%;
  height: 480px;
  background: linear-gradient(to bottom, transparent calc(100% - 90px), #A03592 0);
}

#close-button {
  background: none;
  border: none;
  margin-top: -10px;
  margin-right: -10px;
}

#banner-title {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  text-align: center;
  margin-top: 5px;
  color: #A03592;
}

#learn-more-button {
  border: none;
  color: black;
  text-align: center;
  font-size: 18px;
  font-family: "Abel", serif;
  letter-spacing: 0.7px;
  border-radius: 24px;
  width: 50%;
  height: 42px;
  top: 600px;
  left: 127px;
  padding: 8px 0px 8px 0px;
  margin-top: 20px;
}

.space-right-md {
  margin-right: 10px;
}

.space-left-md {
  margin-left: 10px;
}

#banner-image-group {
  margin-top: -5px;
  height: 335px;
  width: auto;
  margin-left: -20px;
  max-width: 100%;
  background: linear-gradient(to top, transparent calc(100% - 20px), #FFFFFF);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 20px; /* Space between columns */
  align-items: center; /* Vertically center the content */
}

.text-container {
  padding: 20px;
}

.title-formatting {
  text-align: left;
  color: black;
  margin-left: -30px;
  font-family: 'Lato Bold', sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 40px; /* 181.818% */
}

.list-formatting {
  list-style-type:none;
  margin-left: -60px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  margin-bottom: 0;
}

.discounts-button {
  border-radius: 24px;
  border: 2px solid #FFA6F6;
  height: 44px;
  width: 270px;
  background: white;
  box-shadow: 0px 44px 12px 0px rgba(0, 0, 0, 0.00), 0px 28px 11px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  color: #A03592;
  font-family: 'Lato Bold', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 26px; /* 130% */
  letter-spacing: 0.46px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  margin-left: 20px;
}

//Adjustments for smaller screens
@media (max-width: 1199px) and (min-width: 992px) {
  #banner-outer {
    height: 430px; /* Decrease height for medium screens */
    background: linear-gradient(to bottom, transparent calc(100% - 70px), #A03592 0);
  }
  #banner-image-group {
    height: auto;
    width: 600px;
    max-width: 100%;
    max-height: 100%;
  }

  .title-formatting {
    font-size: 18px;
    margin-bottom: 0;
  }

  .list-formatting {
    font-size: 14px;
  }

  .less-line-space {
    line-height: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  #learn-more-button {
    font-size: 14px;
    margin-top: 15px;
  }

  #banner-title {
    font-size: 18px;
  }

  .discounts-button {
    height: 35px;
    width: 200px;
    font-size: 12px;
    margin-left: 0;
    margin-top: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 2px;
  }
}

@media screen and (max-width: 991px) {
  #banner-image-group {
    display: none; /* Hide the image on screens smaller than 768px */
  }

  .grid-container {
    display: flex;
    align-items: center;
    gap: 0;/* Vertically center the content */
  }

  #banner-title {
    font-size: 18px;
  }

  .title-formatting {
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
    margin-left: 0;
  }

  .list-formatting {
    font-size: 14px;
    line-height: 60px;
  }

  @-moz-document url-prefix() {
    @media (max-width: 590px) and (min-width: 573px) {
      .list-formatting {
        line-height: 45px;
      }
    }
  }

  #learn-more-button {
    font-size: 12px;
    margin-top: 30px;
    width: 100%;
  }

  .discounts-button {
    height: 35px;
    width: 200px;
    font-size: 12px;
    margin-left: 0;
    margin-top: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 2px;
  }

  .small-screen-text {
    text-align: center;
    display: table;
    margin: 0 auto;
  }
}

@media screen and (max-width: 572px) {
  #learn-more-button {
    font-size: 14px;
    margin-top: 45px;
    @media screen and (max-width: 529px) {
      overflow-wrap: break-word;
      height: auto;
      margin-top: 10px;
      margin-bottom: -10px;
    }
  }

  @-moz-document url-prefix() {
    @media (max-width: 540px) and (min-width: 530px) {
      #learn-more-button {
        margin-top: 10px;
        height: 100%;
      }
    }

    @media (max-width: 550px) and (min-width: 541px) {
      #learn-more-button {
        margin-top: 33px;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 529px) {
    #banner-outer {
      height: auto;
    }
  }

  .list-formatting {
    line-height: 40px;
  }

  .discounts-button {
    margin-top: 15px;
  }
}

//

.button-grid {
  display: grid;
  grid-template-columns: auto auto;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: auto;
  }
  @media (max-width: 564px) {
    grid-template-columns: auto;
  }
  gap: 5px; /* Space between columns */
  align-content: center;
}

.journal-button {
  width: auto;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  border-color: #4678b2;
  border-width: 2px;
}