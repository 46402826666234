.cherry-garden-statements-table {
  border-collapse: collapse;

  thead > tr > th {
    border: 1px solid black !important; // bootstrap using very specific selector we need to override
    text-align: center;
  }

  & tbody {

    & > tr {
      &:last-child .cherry-garden-empty-cell {
        border-bottom: 1px solid black;
      }

      &.invisible {
        height: 10px; // Provides spacing between the populated rows
      }

      & > td {
        z-index: 0;
        padding: 0;
        border: 1px solid black;

        &.cherry-garden-statement-cell {
          vertical-align: top;
        }

        &.cherry-garden-empty-cell {
          border-bottom: 0;
        }

        &.button-wrapper {
          border-top: 0;
        }

        & .cherry-garden-statement-wrapper {
          padding-bottom: 10px;

          & .cherry-garden-score-carried-over {
            position: absolute;
            top: -5px;
            left: 0;
            font-size: 2em;
          }
        }

        & .cherry-garden-statement-text {
          padding: 10px;
          margin: 0;
        }

        & .cherry-garden-average-refinement {
          position: relative;
          text-align: center;
          font-size: 0.9em;
          background-color: orange;
          padding: 10px 0;
        }

        & .cherry-garden-branch-map-button {
          color: white;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #337ab7;
          border-color: #2e6da4;
          cursor: pointer;
        }

        & .cherry-garden-branch-map-button--top-border {
          @extend .cherry-garden-branch-map-button;

          border-top: 1px solid black;
        }

        & .cherry-garden-tick-statement {
          @extend .cherry-garden-branch-map-button;
        }

        & .cherry-garden-locked-statement {
          @extend .cherry-garden-branch-map-button;

          cursor: auto;
        }

        & .cherry-garden-level-two-criteria {
          padding: 0;
          margin: 0 10px 0 15px;
          list-style-type: none;

          & li {
            padding-top: 15px;
          }

          & input[type=checkbox] {
            margin-right: 10px;
          }

          .checked:before {
            font-family: FontAwesome;
            content: "\f00c";
            margin:0 5px 0 -10px;
          }

          .unchecked:before {
            font-family: FontAwesome;
            content: "\f00d";
            margin:0 5px 0 -10px;
          }

          label {
            font-weight: normal;
          }
        }
      }
    }
  }
}

.cherry-garden-refinement-levels {
  & a {
    padding: 0;
  }

  & li {
    cursor: pointer;
    padding: 8px 5px;
  }
}

.cherry-garden-statements-table--baseline {
  @extend .cherry-garden-statements-table;

  .cherry-garden-branch-override {
    cursor: pointer;

    &:hover {
      background-color: darken(#FFF, 5%);
    }
  }

  tbody {
    tr td {
      & .cherry-garden-level-two-criteria {
        & input[type=checkbox] {
          margin-right: 5px; //
        }
      }
    }
  }

}

.cherry-garden-statements-table--export {
  @extend .cherry-garden-statements-table;

  & tbody {

    & > tr {
      &:last-child .cherry-garden-empty-cell {
        border-bottom: 1px solid black;
      }

      &.invisible {
        height: 10px; // provides spacing between the populated rows
      }

      & > td {
        &.cherry-garden-empty-cell {
          border-bottom: 1px solid black;
        }
      }
    }
  }
}

.cherry-garden-label {
  display: inline-block;
  margin-bottom: 10px;
  line-height: unset;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.cherry-garden-rounded-label {
  padding: 10px;
  border-radius: 10px;
}

.cherry-garden-refinements {
  padding: 10px;
}

.cherry-garden-targets {
  & td {
    vertical-align: middle !important;
  }

  & .cherry-garden-targets__narrow-column {
    width: 10%;
  }
}

.cherry-garden-achieved-target {
  color: forestgreen;
}

.cherry-garden-strand-key {
  & .cherry-garden-strand-key__label {
    font-size: 0.75em;
    vertical-align: super;
    margin-right: 10px;
  }

  & .cherry-garden-strand-key__colour {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}

.cherry-garden-strand-0 {
  background-color: #FFFF66 !important;
}

.cherry-garden-strand-1 {
  background-color: #99FF99 !important;
}

.cherry-garden-strand-2 {
  background-color: #66FFFF !important;
}

.cherry-garden-strand-3 {
  background-color: #FF99FF !important;
}

.cherry-garden-strand-4 {
  background-color: #FFFF99 !important;
}

.cherry-garden-strand-5 {
  background-color: #99CCFF !important;
}

.cherry-garden-strand-6 {
  background-color: #FF99CC !important;
}

.cherry-garden-strand-7 {
  background-color: #66FFFF !important;
}

.cherry-garden-strand-8 {
  background-color: #FFFF99 !important;
}

.cherry-garden-strand-9 {
  background-color: #99FF99 !important;
}

.cherry-garden-strand-10 {
  background-color: #66FFFF !important;
}

.cherry-garden-strand-11 {
  background-color: #FFFF00 !important;
}

.cherry-garden-strand-12 {
  background-color: #99FF99 !important;
}

.cherry-garden-strand-13 {
  background-color: #FFFF00 !important;
}

.cherry-garden-strand-14 {
  background-color: #92D050 !important;
}

.cherry-garden-strand-15 {
  background-color: #8DB3E2 !important;
}

.cherry-garden-strand-16 {
  background-color: #92CDDC !important;
}

.cherry-garden-strand-17 {
  background-color: #FFFF00 !important;
}

.cherry-garden-strand-18 {
  background-color: #99FF99 !important;
}

.cherry-garden-strand-19 {
  background-color: #F79646 !important;
}

.cherry-garden-strand-20 {
  background-color: #FFFF00 !important;
}

.cherry-garden-strand-21 {
  background-color: #8DB3E2 !important;
}

.cherry-garden-strand-22 {
  background-color: #92D050 !important;
}

.cherry-garden-strand-23 {
  background-color: #ED7D31 !important;
}

.cherry-garden-strand-24 {
  background-color: #FFFF99 !important;
}

.cherry-garden-strand-25 {
  background-color: #FFFF99 !important;
}

.cherry-garden-strand-26 {
  background-color: #FFFF99 !important;
}

.cherry-garden-strand-27 {
  background-color: #FFFF00 !important;
}

.cherry-garden-strand-28 {
  background-color: #92D050 !important;
}

.cherry-garden-strand-29 {
  background-color: #8DB3E2 !important;
}

.cherry-garden-child-pagination-links .pagination {
  margin: 0 !important;
}
