@use "../bespoke_flag_colours";

.bespoke-flag-base {
  color: black; /* default can be changed */
  background-color: white; /* default can be changed */
}

@each $name, $colour in bespoke_flag_colours.$colours {
  $foregroundColour: inherit;
  @if(index(bespoke_flag_colours.$rainbow-colour-palette-white-foreground, $name)) {
    $foregroundColour: white;
  }
  @if(index(bespoke_flag_colours.$bespoke-colours-black-foreground, $name)) {
    $foregroundColour: black;
  }

  .background-#{$name}-bespoke-flag, .#{$name}-bespoke-flag, .static-#{$name}-bespoke-flag {
    @extend .bespoke-flag-base;
  }
  .background-#{$name}-bespoke-flag {
    border: 1px solid #{$colour};
    box-sizing: border-box;
  }
  .bespoke-flag.background-#{$name}-bespoke-flag {
    color: rgb(20,20,20);
    .handle {
      color: $foregroundColour;
    }
  }
  .#{$name}-bespoke-flag, .handle-background-#{$name}-bespoke-flag, .static-#{$name}-bespoke-flag {
    background-color: #{$colour};
    color: $foregroundColour;
  }

  .picker-#{$name}-bespoke-flag {
    background-color: $colour;
    border-color: $colour;
  }

  .bespoke-flag-set-#{$name}-root {
    background-color: #{$colour};
    color: $foregroundColour;
    width: 75%;
    margin-bottom: 0;
    padding: 8px;
    border-radius: 10px;
  }
}
