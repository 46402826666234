.page-size-chooser {
  vertical-align: top;
  margin-top: 20px;
  display: inline-block;
}

.page-size-chooser.page-size-chooser--no-space, .page-size-chooser--no-space .page-size-chooser {
  margin: 0;
}

.pagination.pagination--no-space, .pagination--no-space .pagination {
  margin: 0;
}
