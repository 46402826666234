@font-face {
  font-family: 'texgyreadventorregular';
  src: url('/fonts/texgyreadventor-regular-webfont.eot');
  src: url('/fonts/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgyreadventor-regular-webfont.woff') format('woff'),
  url('/fonts/texgyreadventor-regular-webfont.ttf') format('truetype'),
  url('/fonts/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'tex_gyre_bonumregular';
  src: url('/fonts/texgyrebonum-regular-webfont.eot');
  src: url('/fonts/texgyrebonum-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgyrebonum-regular-webfont.woff') format('woff'),
  url('/fonts/texgyrebonum-regular-webfont.ttf') format('truetype'),
  url('/fonts/texgyrebonum-regular-webfont.svg#tex_gyre_bonumregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'comicneuebold';
  src: url('/fonts/ComicNeue-Bold.eot');
  src: url('/fonts/ComicNeue-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/ComicNeue-Bold.woff') format('woff'),
  url('/fonts/ComicNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.tapestry-font {
  &.indie-flower {
    font-family: "Indie Flower", serif;
  }

  &.shadows-into-light {
    font-family: "Shadows Into Light", serif;
  }

  &.architects-daughter {
    font-family: "Architects Daughter", serif;
  }

  &.helvetica {
    font-family: Helvetica, serif;
  }

  &.georgia {
    font-family: Georgia, serif;
  }

  &.courier {
    font-family: "Courier New", "Courier", monospace;
  }

  &.avantgarde {
    font-family: "Times New Roman", serif;
  }

  &.bookman {
    font-family: "Bookman", serif;
  }

  &.adventor {
    font-family: "texgyreadventorregular", sans-serif;
  }

  &.arimo {
    font-family: 'Arimo', sans-serif;
  }

  &.bonum {
    font-family: "tex_gyre_bonumregular", serif;
  }

  &.cousine {
    font-family: 'Cousine', sans-serif;
  }

  &.noto-sans {
    font-family: 'Noto Sans', sans-serif;
  }

  &.noto-serif {
    font-family: 'Noto Serif', serif;
  }

  &.tinos {
    font-family: 'Tinos', serif;
  }

  &.comic-neue {
    font-family: 'comicneuebold', sans-serif;
  }

  &.roboto {
    font-family: 'Roboto',  serif;
  }

  &.roboto-thin {
    font-family: 'Roboto Thin',  serif;
  }

  &.roboto-thin {
    font-family: 'Roboto Light',  serif;
  }

  &.roboto-medium {
    font-family: 'Roboto Light',  serif;
  }
}
