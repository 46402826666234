/* ACTIONS */

.action-metadata {
  display: none;
  word-wrap: break-word;
  // Intentionally specifying this twice so that browsers that support "break-word" will use it instead of "break-all"
  word-break: break-all;
  word-break: break-word;
}

.action-metadata-toggle {
  margin-top: 10px;
}

.action-history__heading {
  position: relative;
}

.action-history__link {
  position: absolute;
  padding-right: 1.5em;
  right: 0;
  top: 0.6em;
}

.action-history__button {
  @extend .action-history__link;
  top: 0;

  @media(max-width: 450px) {
    position: static;
    margin-bottom: 1em;
  }
}

.quick-action-links {
  list-style: none;
  padding-left: 0;
  margin-bottom : 20px;

  & li:not(:last-of-type) {
    padding-bottom: 5px;
  }

  & + .pagination {
    margin-top: 0; // We've already added some margin
  }
}

.alert-no-actions {
  text-align: center;
  font-size: 2em;
}

.action-no-previous-record-footnote {
  padding: 10px 0 0 25px;
  font-size: 0.75em;
}

.action-metadata__empty-value {
  opacity: 0.5;
}
