.inline-icon {
  height: 54px;
  display: table;
}

.inline-icon i {
  color:white;
  font-size: 26px;
  display: table-cell;
  vertical-align: middle;
}

.key-child-box {
  -webkit-transition: 0.5s; /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}

.key-child-box:hover {
  background-color: rgba(0,0,0,0.3);
  cursor: pointer;
}

.list-group-wrapper {
  max-height: 1000px;
  overflow: auto;
}