/**
 * Third-party overrides
 */
.ui-autocomplete {
  position: absolute;
  cursor: default;
  z-index: 1001 !important;

  &.ui-menu .ui-menu-item-wrapper {
    padding: 15px 15px 15px 10px;
  }
}

.care-diary-entry {
  & .bootstrap-datetimepicker-widget {
    font-size: 0.75em;

    & table td {
      height: 30px;
      line-height: 30px;

      & span {
        font-size: 1.5em;
        height: 15px;
        line-height: 15px;
      }
    }
  }

  & .select2-container--default .select2-search--inline .select2-search__field {
    font-size: 0.8em;
    padding: 6px;
  }

  & .select2-container--default .select2-selection--multiple .select2-selection__choice {
    font-size: 0.9em;
  }
}

.care-diary-detail__modal {
  & .select2 {
    width: 100% !important;
  }
}

/**
 * Entry form stuff
 */
.care-diary-widget {
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;

  &.highlighted {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
  }

  & input, select, textarea, label {
    font-size: 0.8em;
  }

  & textarea {
    height: 55px;
    min-height: 55px;
    max-height: 250px;
    resize: vertical;
  }

  & li {
    text-align: left;
  }
}

.care-diary-widget__required {
  font-size: 0.75em;
  font-style: italic;
}

.care-diary-main-widget {
  @extend .care-diary-widget;

  width: 250px;
  padding: 10px;
  margin: 0 auto 20px;
}

.care-diary-master-controls__info {
  font-size: 1.25em;
  margin-left: 5px;
}

/**
 * The widgets shown for each child
 */
.care-diary-child-widget {
  @extend .care-diary-widget;

  display: inline-block;
  vertical-align: top;
  width: 250px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  margin: 0 5px 10px;
  padding: 10px;

  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;

  &.highlighted {
    margin: 0 5px 10px;
  }
}

.care-diary-child-widget--edit {
  @extend .care-diary-child-widget;

  margin: 20px 0;
}

.care-diary-child-widget__checkboxes {
  & .checkbox {
    margin: 15px 0;
  }
}


.care-diary-child-widget__errors {
  list-style: none;
  padding: 5px 0;

  & li {
    color: red;
    font-size: 0.8em;
    text-align: center;
  }
}

.care-diary-child-widget__errors--padding {
  @extend .care-diary-child-widget__errors;

  padding-bottom: 15px;
}

.care-diary-child-widget__name {
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;

  & input[type=checkbox] {
    display: block;
    margin: 5px auto 0 auto;
  }

  & .label {
    display: inline-block;
    margin-bottom: 10px;
  }

  &:hover {
    border-radius: 10px;
    background-color: lighten(lightgrey, 15%);
  }
}

.care-diary-child-widget__action-item {
  & + & {
    margin-left: 20px;
  }
}

.care-diary-child-widget__list {
  list-style: none;
  padding: 0;

  & li {
    display: inline-block;
    font-size: 2em;
    padding: 15px 15px;
    cursor: pointer;

    &:hover {
      border-radius: 15px;
      background-color: lighten(lightgrey, 15%);
    }
  }
}

.care-diary-child-widget--index {
  @extend .care-diary-child-widget;

  font-size: 0.9em;
  width: 225px;
}

.care-diary-child-widget--slim {
  @extend .care-diary-child-widget;

  //font-size: 0.9em;
  width: 195px;
}

/**
 * Sleep care entry page
 */
.sleep-care-actions {
  min-height: 150px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
}

.sleep-care-actions__submit {
  margin-top: 1em;
}

.sleep-care__autocomplete {
  position: relative;
  margin-top: 1em;
}

.sleep-care__autocomplete-input {
  width: 90%;
  display: inline-block;
  border-radius: 4px 0 0 4px;
}

.sleep-care__autocomplete-trigger {
  display: inline-block;
  position: absolute;
  width: 10%;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.care-diary-master-controls--sleep-care {
  @extend .care-diary-main-widget;

  &.disabled {
    & .care-diary-child-widget__action i {
      opacity: 0.1;
    }
  }
}

.care-diary-child-widget__action {
  font-size: 3em;
  cursor: pointer;
}

.care-diary-child-widget__action--disabled {
  @extend .care-diary-child-widget__action;

  cursor: auto;
}

/**
 * Staff index page
 */
.care-diary-index-actions {
  width: 240px;
  position: fixed;
  left: 50%;
  bottom: 0;
  margin-left: -120px; /* Negative half of width */

  background-color: #d9edf7;
  border-color: #bce8f1;
  border-radius: 25px;

  list-style: none;
  text-align: center;
  font-size: 2.5em;

  @media (min-width: 575px) {
    width: 600px;
    margin-left: -300px; /* Negative half of width */
    font-size: 3em;

    & + li {
      margin-left: 75px;
    }
  }

  & li {
    display: inline-block;
    cursor: pointer;
    margin: 15px;

  }
}

.care-diary-index-actions--accidents-only {
  @extend .care-diary-index-actions;

  width: 140px;
  margin-left: -70px;

  @media (min-width: 575px) {
    width: 200px;
    margin-left: -100px; /* Negative half of width */
  }
}

.care-diary-index-drinks {
  max-width: 395px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-bottom: 20px;

  & .row div {
    padding: 0;
    margin-top: 10px;
  }
}

.care-diary-index-drinks__list {
  list-style: none;
  margin: 0;
  padding: 0;

  & li {
    display: inline-block;
    margin: 5px 0;

    & i {
      color: red;
      cursor: pointer;
      padding: 10px 10px 10px 5px;
    }
  }
}

.care-diary-index-drinks__heading {
  font-size: 1.2em;
  padding: 5px 15px;

  @media (min-width: 768px) {
    font-size: 1.4em;
  }
}

.care-diary-index-drinks__button {
  display: inline-block;
  margin: 10px auto 0;

  & + & {
    margin-left: 10px;
  }
}

.care-diary-index-drinks__input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 6px 12px;
  width: 150px;
}

.care-diary-index-approval {
  font-size: 0.8em;
  border: 1px solid #ddd;
  border-radius: 10px;

  & ul {
    list-style: none;
    padding-left: 0;
    margin: 10px 0 20px 0;

    & li {
      margin-bottom: 5px;
    }
  }
}

.care-diary-index-notification {
  position: relative;
}

.care-diary-index-notification__indicator {
  border-radius: 10px;
  font-size: 0.4em;
  position: absolute;
  top: 15px;
  left: 35px;
}

/**
 * Child overview for staff
 */
.care-diary-staff-overview__row {
  margin-bottom: 10px;
}

.care-diary-staff-overview__label {
  margin: 0;
}

.care-diary-staff-overview__note {
  width: 90%;
  margin: 0 auto 25px;
  border-radius: 5px;
  background: lightgoldenrodyellow;
  padding: 15px;
}

.care-diary-staff-overview__drinks {
  list-style: none;

  & li {
    margin-bottom: 10px;

    &:before {
      font-family: 'FontAwesome';
      content: '\f067';
      margin:0 5px 0 -15px;
      font-size: 0.5em;
      vertical-align: middle;
    }
  }
}

.care-diary-staff-overview__list {
  list-style: none;
  padding: 5px 20px;
}

.care-diary-staff-overview__food-items {
  list-style: none;
  padding: 0;
}

.care-diary-staff-overview__confirm-deletion {
  display: none;
}

.care-diary-staff-overview__feedback {
  display: none;
}

.care-diary-staff-overview__checkbox {
  transform: translateY(100%);
}

.care-diary-staff-overview__date-separator {
  display: block;
  font-size: 0.9em;
  overflow: hidden;
  text-align: center;
  margin: 20px 0 25px;
  height: 25px;
  line-height: 25px;

  &:before, &:after {
    background-color: #ddd;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

.care-diary-staff-overview__unseen_comment_count {
  font-size: 0.8em;
  border-radius: 8px;
}

.care-diary-staff-overview__button-with-notice {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.care-diary-staff-overview__button-notice {
  .modal-footer .care-diary-staff-overview__button-with-notice + & {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/**
 * Generic child overview
 */

.care-diary-overview__row {
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);

  &:first-of-type {
    margin-top: 10px;
  }
}

.care-diary-overview__nav {
  font-size: 0.75em;
  margin-bottom: 15px;
}

.care-diary-overview__notice {
  padding: 10px;
}

.care-diary-overview__heading {
  margin: 15px 0 25px;
}

/**
 * Meal entry page
 */
.care-diary-meal__tooltip {
  text-align: left;
  font-size: 1.25em;

  & i {
    vertical-align: sub;
  }

  & .tooltip-wide + .tooltip > .tooltip-inner {
    max-width: 100%;
  }
}

/**
 * General
 */
.care-diary__feedback {
  display: none;
  margin-top: 15px;
}

.care-diary__feedback--index {
  @extend .care-diary__feedback;

  max-width: 50%;
  margin: 15px auto 0;
}

select:focus option.care-diary-disabled-select-option {
  display: none;
}

.care-diary__note {
  margin: 0 auto 25px;

  & .child-diary__note-label {
    font-size: 0.75em;
  }

  & .care-diary__note-text {
    border-radius: 5px;
    background: lightgoldenrodyellow;
    padding: 15px;
  }
}

.care-diary__note--slim {
  @extend .care-diary__note;

  width: 90%;
}

.care-diary-table__list {
  padding-left: 0;
  list-style: none;
}

/**
 * Manager
 */

.care-diary-manager__list {
  padding: 0;
  text-align: center;

  // @todo resolve duplication with .care-diary-entry
  & .bootstrap-datetimepicker-widget {
    font-size: 0.75em;

    & table td {
      height: 15px;
      line-height: 15px;

      & span {
        height: 15px;
        line-height: 15px;
      }
    }
  }

  & li {
    display: inline-block;
    list-style: none;
  }

}

.care-diary-manager__meal-time-widget {
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  vertical-align: top;
}

.care-diary-manager__remove-element {
  text-align: center;
  font-size: 0.75em;
  cursor: pointer;
}

.care-diary-manager__autocomplete-wrapper {
  padding: 25px 0 10px;
  text-align: center;
}

.care-diary-manager__autocomplete-item-wrapper {
  width: 95%;
  margin: 8px;
  display: inline-block;
  vertical-align: top;

  @media (min-width: 576px) {
    width: 45%;
  }
}

.care-diary-manager__autocomplete-errors {
  padding: 0;
  list-style: none;

  & li {
    color: red;
    font-size: 0.75em;
  }
}

.care-diary-manager__autocomplete-item {
  margin-bottom: 10px;
}

/**
 * Toggle widget
 */

.care-diary-toggle__switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;

  & input {
    display: none;
  }
}

.care-diary-toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  input:checked + & {
    background-color: #2196F3;
  }

  input:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + &:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 8px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

.care-diary-toggle__slider--round {
  @extend .care-diary-toggle__slider;

  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}

.care-diary-toggle__label {
  display: inline-block;
  vertical-align: top;
  padding: 5px 5px 0;
  font-size: 0.75em;
}

/**
 * Entry detail
 */

.care-diary-detail__digital-signature-wrapper {
  position: relative;
}

.care-diary-detail__digital-signature {
  display: block;
  border: 1px solid;
}

.care-diary-detail__digital-signature-reset {
  position: absolute;
  text-align: center;
  top: 0;
  right: 3px;
  border-radius: 0;
}

.care-diary-detail__list {
  margin-top: 10px;
}

.care-diary-panel-heading a:after {
  font-family: 'FontAwesome';
  font-size: 1.5em;
  content:"\f0d7";
  float: right;
  color: grey;
}

.care-diary-action-buttons {
  @media only screen and (max-width: 484px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.care-diary-panel-heading a.collapsed:after {
  content:"\f0da";
}

.body-maps-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.body-maps-adjustable {
  width: max-content;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;

  .digital-signature__canvas {
    border: none;
  }
  .digital-signature__wrapper {
    border: 1px solid #ddd;
    max-width: 702px; // slightly bigger than the canvas to allow for scrollbars
    max-height: 502px;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
     width: 10px;
     height: 11px;
     @media (max-width: 1024px) {
       width: 20px;
       height: 21px
     }
    padding: 4px 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.body-maps-clear-button {
  display: none;
}

.body-maps-clear-button {
  display: inline-block;
}

//make button alignment tidy on smaller screens
@media (max-width:425px) {

  #complete-body-map {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  #cancel-body-map {
    margin-right: -5px;
    margin-left: 10px;
  }
}

.body-maps-background {
  background-image: url('/images/body_map.png');
}

.js-body-maps-hidden-canvas {
   background-image: url('/images/body_map.png');
}

.body-maps-thumbnail-background {
  width: 100%;
  height: auto;
  max-width: 700px;
}

.body-map-detail-photo {
  width: 100%;
  height: auto;
  max-width: 700px;
}

.body-map-detail-photo-modal__image {
  width: 85%;
  height: 85%;
  margin: auto;
}

.accident-tracking-bodymap__image {
  width: 100%;
  height: 100%;
  border: 0.5px solid;
  margin: auto;
}

.body-map-pdf-text {
  vertical-align: top;
}

.filler-space {
    height: 49px;
}

//care diary export section
.checkbox-container, .sort-entry-container, .include-comments-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.checkbox-item, .sort-entries-item, .include-comments-item{
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.checkbox-container label, .sort-entry-container label, .include-comments-container label {
  margin-bottom: 0;
}

.checkbox-container input, .sort-entry-container input, .include-comments-container input {
  margin-top: 0;
  margin-right: 5px;
}
