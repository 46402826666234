.load-dots {
  margin: 0px auto 0px;
  width: 70px;
  min-width: 70px;
  text-align: center;
}

.load-dots--spacing {
  @extend .load-dots;

  margin: 25px auto;
}

.load-dots > div {
  width: 16px;
  height: 16px;
  background-color: #636363;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.load-dots .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.load-dots .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
