.manager-overview .big-text {
    font-size: 30px;
}

.manager-overview .overview-top-shelf {
    text-align: center;
}

.actions {
    .action-buttons {
        margin-top: 10px;

        .btn {
            margin-bottom: 5px;
            @media (max-width: 767px) {
                display: inline-block;
                margin-bottom: 6px;
                max-width: 40%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .action-date {
        margin-top: 15px;
    }

    .list-group-item {
        padding: 10px 15px 5px 15px;
    }
}

.manager-item-buttons {
    margin-top: 10px;

    @media (min-width: 992px) {
        margin-top: 0;
    }
}

// Upload Button
[data-button-id="photo-upload"] {
    text-overflow: ellipsis;
    overflow: hidden;
    display: none;
}

[data-photo-editor-drop-target] {
    margin: 5px 0;
}

// Permissions

.permissions-left {
    width: 80%;

}

.permissions-left h4 {
  line-height: 1.3;
}

.manager-breadcrumbs {
    margin-top: 55px;
}

#controlPanelModal .modal-dialog {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#controlPanelModal .modal-header {
    border-bottom: 0;
}

#controlPanelModal .modal-footer {
    border-top: 0;
}

.manager-setup-info {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 15px;
}

#legal-Contracts-table tr td {
    cursor: pointer;
}
