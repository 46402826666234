#summative-assessments-periods-edit {

  margin-bottom: 20px;

  .summative-assessments-periods-defaults {
    margin-left: 10px;
  }

  table {
    border-spacing: 20px 10px;
    margin-bottom: 10px;

    .select-month {
      margin-right: 10px;
    }

  }
}
