.introjs-hint {
  z-index: 999;
}

.introjs-helperNumberLayer {
  background: #34617a;
}

.introjs-bullets ul li a.active {
  background: #34617a;
}

.introjs-button {
  // Copied .btn style from bootstrap
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Copied .btn-default from bootstrap
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;

  // Custom
  margin-top: 20px;
}

.introjs-prevbutton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.introjs-nextbutton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Toastify__toast-container--top-right {
  top:80px !important; // Account for menu without starting a z-index war
}
