.tapestry-warning {
  background-color: rgba(252, 131, 5, 0.79);
  border-bottom: solid 1px #000;
  width: 100%;
  padding: 5px;
  text-align: center;
}

.tapestry-info {
  background-color: rgba(255, 205, 5, 0.85);
  width: 100%;
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid rgba(175, 139, 4, 0.85);
  display: inline-block;

  a {
    color: #000;
    text-decoration: underline;
  }

  &.combined {
    text-decoration: none;
    color: black;
  }
}

.tapestry-setup {
  background: var(--TBlue-700, #253B66);
  border-bottom: 1px solid rgba(175, 139, 4, 0.85);
  border-top: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  color: #fff;
  padding: 5px;

  a {
    color: #fff;
    text-decoration: none;
  }

  &.combined {
    text-decoration: none;
    color: black;
  }
}

.small-alert {
  padding:5px;
  margin-bottom:5px;
}

#password-criteria {
  margin-bottom: 15px;
}

.text-highlight {
  background-color: #ff0;
}

.search-result {
  line-height: 1.3em;
}
