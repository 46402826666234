.navbar-child-main > li > a {
  padding-top: 5px;
  padding-bottom: 5px;

  img {
    height: 40px;
  }
}

.btn-child {
  position: relative;
  display: inline-block;
  padding: 10px 0;
  overflow: hidden;

  .btn {
    font-size: 16px;

    .btn-img {
      position: absolute;
      height: 18px;
    }

    &.btn-add-obs {
      padding-left: 90px;

      .btn-img {
        height: 80px;
        left: 5px;
        top: -15px;
      }
    }

    &.btn-edit-obs {
      padding-left: 100px;

      .btn-img {
        height: 80px;
        left: 5px;
        top: -10px;
      }
    }

    &.btn-add-comment {
      padding-left: 80px;

      .btn-img {
        height: 70px;
        left: 5px;
        top: -5px;
      }
    }

    &.btn-add-media {
      padding-left: 80px;

      .btn-img {
        height: 70px;
        left: 5px;
        top: -20px;
      }
    }

    &.btn-delete-media {
      padding-left: 80px;

      .btn-img {
        height: 70px;
        left: 5px;
        top: -3px;
      }
    }

    &.btn-cancel {
      padding-left: 70px;

      .btn-img {
        height: 60px;
        left: 5px;
        top: -1px;
      }
    }

    &.btn-save {
      padding-left: 70px;

      .btn-img {
        height: 60px;
        left: 5px;
        top: -1px;
      }
    }

    &.btn-back-to-list {
      padding-left: 70px;

      .btn-img {
        height: 60px;
        left: 5px;
        top: -1px;
      }
    }

    &.btn-child-login {
      width: 100%;

      .btn-img {
        height: 60px;
        left: 5px;
        top: 2px;

        @media(max-width: 320px) {
          height: 30px;
          left: 10px;
          top: 18px;
        }
      }
    }

    &.btn-read-obs, &.btn-read-activity {
      width: 100%;
      padding-left: 80px;

      .btn-img {
        height: 70px;
        left: 5px;
        top: -5px;
      }
    }
  }
}

.btn-child-login-container {
  width: 100%;
  padding: 15px 0;
}

.btn-magic-words {
  display: inline-block;
  padding-left: 72px;
  text-align: left;
  position: relative;
  margin-top: 90px;
  &.btn {
    .btn-img {
      height: 72px;
      left:-6px;
      top: -12px;
      position: absolute;
    }
  }
  .btn-primary { white-space: normal }
}

.btn {
  overflow: visible;
  .btn-img {
    height: 18px;
  }
}

.pager.child-mode {
  font-size: 17px;

  .previous, .next {
    .btn-img {
      height: 20px;
      margin-top: -3px;
    }
  }
}

.login-container {
  position: relative;
  .btn-close-top-right {
    position: absolute;
    right: -12px;
    top: -12px;
    img {
      height: 36px;
    }
  }
}

.computer-enabled {
  float: right;
  border: #2e6da4;
  padding: 2px 5px;
  background-color: #337ab7;
  color: white;
}

.computer-not-setup {
  margin: 60px auto;
  text-align: center;
  .panel-heading {
    font-size: 3em;
  }
  p {
    font-size: 1.5em;
    margin-top: 10px;
  }
}
