// CATEGORY DISPLAY PICKER

.bespoke-flag-display-picker {
  display: inline-flex;

  // Style the custom control
  &__control {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    height: 150px;
    cursor: pointer;
    border: 3px solid #d5d5d5;
    border-radius: 5px;
    font-weight: normal;

    &:hover {
      box-shadow: 0 0 5px #737373;
    }
  }

  // Checked icon hidden unless checked
  &__control-checked-icon {
    visibility: hidden;
  }

  &__control-label {
    font-weight: bold;
    padding: 5px;
  }

  &__description {
    font-size: 12px;
    padding: 5px;
    margin-bottom: 2px;
  }

  // Make the native input invisible but keep it functioning
  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  // Style the custom control in checked state
  input:checked + .bespoke-flag-display-picker__control {
    border: 3px solid black;

    .bespoke-flag-display-picker__control-checked-icon {
      visibility: visible;
    }
  }

  // Style the custom control in focus state
  input:focus + .bespoke-flag-display-picker__control {
    box-shadow: 0 0 5px #888;
  }
}
