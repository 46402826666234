@use 'bespoke_flag_colours';

// Colour palette adapted from http://clrs.cc/ v3.0.0

// All colours
$colour-palette: (
  "navy": #001f3f,		// unchanged 
  "blue": #2b87d8,		// reduced saturation to 80 
  "aqua": #7fdbff,		// unchanged 
  "teal": #39cccc,		// unchanged 
  "olive": #3d9970,	    // unchanged 
  "green": #2ecc40,	    // unchanged 
  "lime": #4cff6a,		// reduced saturation to 70, reduced hue to 130 
  "yellow": #ffb219,	// reduced saturation to 80, reduced hue to 40
  "orange": #ff7632,	// reduced saturation to 80, reduced hue to 20
  "red": #ff4136,       // unchanged
  "maroon": #99265d,	// reduced saturation to 75, increased brightness to 60
  "fuchsia": #cc3daa,	// reduced saturation to 70, reduced brightness to 80 
  "purple": #8a2d99,	// reduced saturation to 70, reduced brightness to 60
  "black": #111111,     // unchanged
  "grey": #aaaaaa,      // unchanged
  "silver": #dddddd,    // unchanged
  "white": #ffffff,     // unchanged
);

// Colours which should have a white foreground
$colours-with-white-foreground: ( "navy", "blue", "maroon", "purple", "black"  );

// Generate foreground and background classes
@each $colour-name, $colour in $colour-palette {
  /* Foreground #{$colour-name} */
  .#{$colour-name} {
    color: $colour;
  }
  /* Background #{$colour-name} */
  .bg-#{$colour-name} {
    background-color: $colour;
  }
}

// Generate classes to put white foreground on some colours
@each $colour-name in $colours-with-white-foreground {
  /* Foreground for #{$colour-name} background */
  .bg-#{$colour-name}-fg {
    color: white;
  }
}

// All colours for charts
$chart-colour-palette: (
  "grey": #4d4d4d,
  "blue": #5da5da,
  "orange": #faa43a,
  "green": #60bd68,
  "pink": #f17cb0,
  "brown": #b2912f,
  "purple": #b276b2,
  "yellow": #decf3f,
  "red": #f15854,
);

// Generate foreground and background classes
@each $colour-name, $colour in $chart-colour-palette {
  /* Foreground #{$colour-name} */
  .chart-#{$colour-name} {
    color: $colour;
  }
  /* Background #{$colour-name} */
  .chart-bg-#{$colour-name} {
    background-color: $colour;
  }
}

// All colours for Australian Developmental Milestones age ranges
$adm-colour-palette: (
  "1": #00adee,
  "2": #cb2126,
  "3": #9a1c83,
  "4": #3dad48,
  "5": #005eab,
  "6": #ef7523,
);

// Generate foreground and background colours
@each $colour-name, $colour in $adm-colour-palette {
  /* Foreground #{$colour-name} */
  .adm-#{$colour-name} {
    color: $colour;
  }
  /* Background #{$colour-name} */
  .adm-bg-#{$colour-name} {
    background-color: $colour;
  }
  /* Foreground #{$colour-name} faded */
  .adm-#{$colour-name}-faded {
    color: desaturate($colour, 15%);
  }
  /* Background #{$colour-name} faded */
  .adm-bg-#{$colour-name}-faded {
    background-color: desaturate($colour, 15%);
  }
}

// For KS teacher assessment
.ks-ta-req-icon {
  color: #31708f;
}

// Bespoke flag/assessment colours
@each $name, $colour in bespoke_flag_colours.$colours {
  .bespoke-bg-#{$name} {
    background-color: $colour;
  }
}
