/* RELATIVE HELP */

.relative-help__links {
  margin: 20px 0 20px 0;

  & a {
    margin: 0 5px 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    text-align: center;
  }
}
