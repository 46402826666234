/* VIEW NOTICES */

/* BROWSE NOTICES */

  .notice-item {
    .media-left {
      /* The "Awaiting Deletion" label is a bit long so we'll make it all line up with this */
      min-width: 110px;
    }

    &.status-draft {
      border-left: 5px solid #f0ad4e;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #F6C53F; /* Old browsers */
      background: -moz-linear-gradient(left,  #F6C53F 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F6C53F), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #F6C53F 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6C53F', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-deleted {
      border-left: 5px solid red;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #f9c7c7; /* Old browsers */
      background: -moz-linear-gradient(left,  #f9c7c7 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#f9c7c7), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9c7c7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }

.media-left-notice {
  .notice-status {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.notice-icon{
  color: #F6C53F;
}

.notice-frame:hover {
  -webkit-box-shadow: 0px 0px 7px 0px #62a8e9;
  -moz-box-shadow: 0px 0px 7px 0px #62a8e9;
  box-shadow: 0px 0px 7px 0px #62a8e9;

  .media-heading {
    color: #337ab7;
    text-decoration: underline;
  }
}

/* MANAGE NOTICES */


