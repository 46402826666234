.reference-items {
  &__item {
    span.list-group-item-heading {
      color: #337ab7;
      &:hover {
        text-decoration: underline;
      }
    }
    p.list-group-item-text {
      margin-top: 5px;
    }
    h4 {
      margin: 0;
      font-size: 16px;
      i { color: #337ab7 }
    }
  }
  &__panel-footer {
    padding: 5px 15px;
    &__pagination {
      display: inline-block;
      width: 49%;
      padding: 10px;
    }
  }
}

.reference-items--loading {
  @extend .reference-items;

  height: 100%;
}

.reference-material {
  @media(max-width: 1200px) {
    width: 100%;
  }
}

.modal {
  .modal-header {
    a.back {
      float: right;
      margin-right: 10px;
    }
  }
}

.reference-item-toggle-collapse {
  color: inherit;
  cursor: pointer;
}
