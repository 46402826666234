#attach-new-child {
  margin-bottom:20px;

  ul.dropdown-menu {

    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

  }
}

#attached_children {

  li {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
  }

}

.manage-relatives-pagetitle {
  font: 300 20px/1.3 Helvetica, Arial, sans-serif;

}

.relative-child {
  @media only screen and (max-width: 991px) {
    float: right;
  }

  @media only screen and (min-width: 992px) {
    padding-top: 5px;
  }
}

.relative-export-action {
  width: 100%;
}
