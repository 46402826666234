/* Analysis Sub Menu and Tab Bar styles */

#analysisTabs.collapse.in {
  display: none !important;
}

.analysisMenuTabBar {
  padding: 0px 0px;
  width: 100%;
  border-bottom: 2px solid #000;
  position: absolute;
  z-index: 1009;


  .well {
    margin-bottom: 10px;
  }
}

.analysisMenuTabBar [class*="col-"] {
  margin-top: 0px;
  /* margin-bottom: 5px; */
  font-size: 1em;
  /* text-align: center; */
  line-height: 2;
  background-color: #fff !important;
  /* border-bottom: 2px solid #000; */

}

.analysisMenuTabBar {
  /* display: none; */
  /* height: 0; */
  /* visibility: hidden; */
}

#analysisTabsPin {
  padding-bottom: 10px;
}

#analysisTabsPin.affix {
  position: fixed;
  z-index: 1009;
}

/* Unhinge Bootstrap affix on mobile */
@media (max-width: 640px) {
  #analysisTabsPin.affix {
    position: static;
    top: 20px;
    z-index: 1009;
  }
  .analysisMenuTabBar {
    z-index: 1009;
  }
}

/* Shorter wide screens will need the tab menu to be scrollable for some frameworks */
/* so make Bootstrap affix static here too */
@media (max-height: 1024px) {
  #analysisTabsPin.affix {
    position: static;
    top: 48px;
    z-index: 1009;
  }
  .analysisMenuTabBar {
    z-index: 1009;
  }
}

/* Overriding Bootstrap nav pills styling for Tracking menu assessment frameworks tabs */

 .nav-pills.nav-pills-trackingMenu > li.active > a, .nav-pills.nav-pills-trackingMenu > li.active > a:hover, .nav-pills.nav-pills-trackingMenu > li.active > a:focus {
  color: #000000;
  background: white;
}

.nav-pills.nav-pills-trackingMenu > li > a {
  color: #c0c0c0;
  background: white;
}

.nav.nav-pills.nav-pills-trackingMenu {
  border-bottom: 2px solid #000000;
}

.analysisMenuTabBar > .col-sm-12 {
  border-bottom: 2px solid #000;

}

#analysisTabsPin {
  //top: 45px;
}


.content {
  position: relative;
}

/* Buttons on the menu */
.btn-trackingmenu {
  color: #000000;
  background-color: #FFFFFF;
  border-color: #000000;
}

.btn-trackingmenu:hover,
.btn-trackingmenu:focus,
.btn-trackingmenu:active,
.btn-trackingmenu.active,
.open .dropdown-toggle.btn-trackingmenu {
  color: #000000;
  background-color: #F0F0F0;
  border-color: #000000;
}

.btn-trackingmenu:active,
.btn-trackingmenu.active,
.open .dropdown-toggle.btn-trackingmenu {
  background-image: none;
}

.btn-trackingmenu.disabled,
.btn-trackingmenu[disabled],
fieldset[disabled] .btn-trackingmenu,
.btn-trackingmenu.disabled:hover,
.btn-trackingmenu[disabled]:hover,
fieldset[disabled] .btn-trackingmenu:hover,
.btn-trackingmenu.disabled:focus,
.btn-trackingmenu[disabled]:focus,
fieldset[disabled] .btn-trackingmenu:focus,
.btn-trackingmenu.disabled:active,
.btn-trackingmenu[disabled]:active,
fieldset[disabled] .btn-trackingmenu:active,
.btn-trackingmenu.disabled.active,
.btn-trackingmenu[disabled].active,
fieldset[disabled] .btn-trackingmenu.active {
  background-color: #FFFFFF;
  border-color: #000000;
}

.btn-trackingmenu .badge {
  color: #FFFFFF;
  background-color: #000000;
}

.trackingmenu-analysis-tool-description {
  line-height: normal;
}

/* Overriding Bootstrap nav bar z-index to play nice with jumbotron */

.navbar .navbar-default {
  z-index: 1010;
}
