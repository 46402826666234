// REFLECTION LIST

.observation-item {
  &.reflection-item {
    .media-left {
      @media (max-width: 767px) {
        // Icons are smaller on smaller screens
        width: 45px;

        // Stop the status label overflowing
        .reflection-status-label {
          display: inline-block;
          max-width: 45px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
        }
      }
      @media (min-width: 768px) {
        // The "Awaiting Deletion" label is a bit long so we'll make it all line up with this
        width: 110px;
      }
    }

    &.status-draft {
      border-left: 5px solid #f0ad4e;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #F0D199; /* Old browsers */
      background: -moz-linear-gradient(left,  #F0D199 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F0D199), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F0D199', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-private, &.status-staff-only, &.status-uk-staff {
      border-left: 5px solid #5fc4fc;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9f4fc+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #c9f4fc; /* Old browsers */
      background: -moz-linear-gradient(left,  #c9f4fc 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9f4fc', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-awaiting-deletion {
      border-left: 5px solid red;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #f9c7c7; /* Old browsers */
      background: -moz-linear-gradient(left,  #f9c7c7 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#f9c7c7), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9c7c7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }
}

a[data-reflection-quick-edit] {
  color: #777777;
  margin-left: 5px;
  font-size: 14px;

  &:hover {
    color: #333333;
  }
}

.reflection-cog-menu {
  @media (min-width: 767px) {
    left: auto;
    right: 0;
  }
}

// REFLECTION FORM

@import "./reflection_tags.scss";

.reflection-tag-picker {
  button {
    border: none;
    word-break: break-word;
  }

  .reflection-tag-picker-category {
    min-width: 150px;

    @include reflection-tag-backgrounds;

    .reflection-tag-picker-category-inner {
      padding: 2px;
      margin: 0;

      .reflection-tag-picker-category-preview-wrapper {
        background-color: white;
        border-radius: 3px;
        padding: 2px 5px 4px;
        line-height: 1.5;

        .btn-group {
          white-space: nowrap;

          .btn {
            float: none;
            display: inline-block;
          }
        }
      }

      .media-body {

        .reflection-tag-picker-collapse-wrapper {
          display: flex;

          .reflection-tag-picker-collapse-button {
            flex-shrink: 0;
            margin-right: 5px;
          }

          .reflection-tag-picker-category-button {
            flex-grow: 1;
          }
        }

        button {
          text-align: left;
          white-space: normal;

          [data-tag-name] {
            display: block;
            width: auto;
            overflow: hidden;
          }

          .reflection-tag-picker-category-icons {
            display: block;
            width: auto;
            float: right;
            padding-left: 5px;
          }

          .reflection-tag-picker-category-description {
            display: block;
            font-size: 0.9em;
          }

          &.reflection-tag-with-visibility-toggle {
            border-radius: 4px 4px 0 0;
          }

          &.reflection-tag-visibility-toggle {
            font-size: 1.5em;
            padding: 0;
            margin-top: 0;
            text-align: center;
            border-top: 1px solid black;
            border-radius: 0 0 4px 4px;
          }

          .reflection-selected-tag-count {
            text-align: center;
            font-size: 0.75em;
          }

          .label[data-tag-count] {
            display: inline;
            &::before {
              content: attr(data-tag-count);
            }

            &:empty {
              display: inline;
            }

            &[data-tag-count="0"] {
              display: none;
            }
          }

          .reflection-tag-picker-checked-icon {
            display: none;
          }
          .reflection-tag-picker-unchecked-icon {
            display: inline-block;
          }

          &.active {
            .reflection-tag-picker-checked-icon {
              display: inline-block;
            }
            .reflection-tag-picker-unchecked-icon {
              display: none;
            }
          }
        }
      }
    }

    .reflection-tag-picker-category-children {
      margin-left: 20px;
    }
  }

  >.reflection-tag-picker-category {
    margin-top: 5px;
    background-color: #f5f5f5;

    &:first-child {
      margin-top: 0;
    }

    &.reflection-tag-picker-can-have-visibility-toggles {
      display: table-cell;
    }
  }
}

// Bespoke flag picker
.reflection-tag-picker.reflection-tag-picker-bespoke {
  overflow: auto;

  .reflection-tag-picker-category {
    // Spacing between sibling flags
    margin-top: 5px;
    // Flag colour class will set border colour, but we actually want the border on the inner element
    border-style: none;

    .reflection-tag-picker-category-inner {
      // Add border here, and inherit the colour from the containing flag
      border-width: 1px;
      border-style: solid;
      border-color: inherit;
      border-radius: 4px;
      // Don't want padding between the border and the button
      padding: 0;
    }
  }

  .reflection-tag-picker-category {
    // Flag colour class needs to set background colour for the picker root, but we don't want it inside the picker
    background-color: white;
  }
}

.reflection-tag-quick-picker-root {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  @include reflection-tag-backgrounds;

  &:last-child {
    margin-right: 0;
  }

  button {
    border: none;
    text-align: left;
  }

  .label[data-tag-count] {
    display: inline;
    &::before {
      content: attr(data-tag-count);
    }

    &:empty {
      display: inline;
    }

    &[data-tag-count="0"] {
      display: none;
    }
  }
}

.reflection-tag-quick-picker-root-label {
  white-space: normal;
}

.reflection-tag-quick-picker-selected-tag {
  display: none;
  margin-bottom: 5px;

  .reflection-tag-quick-picker-selected-tag-button {
    @include reflection-tag-backgrounds;

    text-align: left;
    white-space: normal;
    word-break: break-word;
  }

  &.reflection-tag-quick-picker-selected-tag-selected {
    display: inline-block;
    max-width: 100%; // required for the below flex to work properly in IE11

    & .reflection-tag-quick-picker-selected-tag-selected-group {
      display: flex;
      display: -ms-flexbox;
    }
  }
}

.reflection-tag-view {

  .reflection-tag-view-category {
    padding: 2px 0 2px 2px;
    @include reflection-tag-backgrounds;

    .reflection-tag-view-category-inner {
      padding: 2px;
    }

    .reflection-tag-view-category-name {
      width: auto;
      overflow: hidden; // BFC
    }

    .reflection-tag-view-category-icon {
      width: auto;
      float: right;
    }

    .reflection-tag-view-category-children {
      margin-left: 20px;
    }
  }

  >.reflection-tag-view-category {
    margin-top: 5px;
    padding-right: 2px;
    background-color: #f5f5f5;

    &:first-child {
      margin-top: 0;
    }
  }
}

.reflection-tag-quick-view {
  display: inline;
  word-break: break-word;
}

.reflection-tag-quick-preview {
  overflow-x: auto;
  word-break: break-word;
}

.reflection-tag-quick-view-category {
  word-break: break-word;
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  white-space: normal;
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid transparent;
  @include reflection-tag-backgrounds;
}

.reflection-tag-quick-preview-category {

  .reflection-tag-quick-preview-category-inner {
    margin-bottom: 5px;

    .reflection-tag-quick-preview-category-description {
      display: block;
      width: auto;
      overflow: hidden;
      padding-left: 10px;
    }

    .reflection-tag-quick-preview-category-tag {
      display: block;
      width: auto;
      float: left;
    }

  }

  .reflection-tag-quick-preview-category-children {
    &.reflection-tag-quick-preview-category-children-inline {
      .reflection-tag-quick-preview-category {
        width: auto;
        float: left;
        margin-right: 10px;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

}

.reflection-tag-quick-preview-category-children {
  margin-left: 20px;
}

// REFLECTION VIEW

.reflection-view-toggle-linked-obs-button {
  [data-show-icon] {
    display: none;
  }
  [data-hide-icon] {
    display: inline-block;
  }

  &.collapsed {
    [data-show-icon] {
      display: inline-block;
    }
    [data-hide-icon] {
      display: none;
    }
  }
}

// REFLECTION QUESTIONS

.reflection-question-provider-panel{
  .panel-body {
    padding-bottom: 0;
  }
}

// TAG COUNT BUTTONS

.reflection-tag-count-button {
  margin-bottom: 5px;
}

// REFLECTION LIST ITEM NOTES

.reflection-notes-short {
  display: block;
  margin-bottom: 5px;
  word-wrap: break-word;
}

// REFLECTION LIST ITEM TAGS

.reflection-item-tags {
  margin-top: 5px;

  [data-reflection-no-children] {
    margin-bottom: 5px;
    cursor: help;
  }

  .child-tag {
    margin-top: 0;
  }

  .user-tag {
    margin-top: 0;
  }

  .btn-xs {
    margin-bottom: 5px;
  }
}

// REFLECTION TIMELINE //

.reflection-comment {
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 15px 15px 5px 15px;
}

.reflection-comment-details {
  font-size: 12px;
  padding: 5px 15px 0 15px;
  color: #767676;
}

.timeline-item {
  .deleteComment {
    margin-left: 0px;
  }
}

.inline-actions {
  & .inline-action {
    display: inline-block;
    margin-right: 7px;

    // .inline-save initially shown then hidden after .inline-edit is made visible
    &.inline-save, &.inline-edit {
      margin-left: 7px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
