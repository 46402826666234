/* ASSESSMENTS */

.assessment {
  // Hide assessment picker dependencies by default
  [data-depends-on-field][data-depends-on-action=hide], [data-depends-on-field][data-depends-on-action=slide] {
    display: none;
  }

  & input[type="checkbox"] {
    margin-top: 4px;
  }
}

.assessment-box {
  padding: 10px;
  margin-bottom: 10px;
}

.assessment-tab-contents > .tab-pane {
  margin-top: 0px!important;
  padding: 5px 10px!important;
}

a[data-assessment-info-link], span[data-assessment-info-link] {
  color: #337ab7;
}

.eyfs-tab-1 a, .eyfs-panel-1, .eyfs-box-1 {
  background-color: #e3e2ee !important;
}

.eyfs-tab-2 a, .eyfs-panel-2, .eyfs-box-2, .mi-area-1 {
  background-color: #d3eed9 !important;
}

.eyfs-tab-3 a, .eyfs-panel-3, .eyfs-box-3, .mi-area-2 {
  background-color: #d5e1ee !important;
}

.eyfs-tab-4 a, .eyfs-panel-4, .eyfs-box-4, .mi-area-3 {
  background-color: #eeecd0 !important;
}

.eyfs-tab-5 a, .eyfs-panel-5, .eyfs-box-5, .mi-area-4 {
  background-color: #eed4c5 !important;
}

.eyfs-tab-6 a, .eyfs-panel-6, .eyfs-box-6, .mi-area-5 {
  background-color: #eed9e7 !important;
}

.eyfs-tab-7 a, .eyfs-panel-7, .eyfs-box-7 {
  background-color: #e6daee !important;
}

.assessment-tabs li {
  margin-bottom: 10px;

  a {
    color: black;
  }
}

.assessment-tabs li.active a, .assessment-tabs li.active a:hover, .assessment-tabs li.active a:focus, .assessment-tabs li a:hover {
  color: black;
  background-color: rgba(0, 0, 0, 0.15);
}

.assessment-panel {
  background-color: rgba(0,0,0,0.05);
  border: 2px solid rgba(0,0,0,0.1);
  margin-bottom: 15px;

  .assessment-panel-heading {
    padding: 10px 15px 5px 15px;
    background-color: rgba(0,0,0,0.05);
    color: black;
    overflow: auto;

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    .assessment-panel-select-left {
      margin-top: 8px;
    }

    .assessment-panel-select-right {
      .form-group {
        margin: 0;
      }

      select {
        margin-bottom: 5px;
      }
    }

    &.cursor-pointer:hover {
      background-color: rgba(0,0,0,0.09);
    }

    .assessment-panel-title {
      margin-bottom: 5px;
      font-size: 14px;
    }

    .fa-caret-down {
      color: rgba(0,0,0,0.6);
    }

    &[aria-expanded=true] .fa-caret-down {
      display: none;
    }

    .fa-caret-up {
      display: none;
    }

    &[aria-expanded=true] .fa-caret-up {
      display: inline;
    }
  }

  .assessment-panel-body {
    background-color: white;

    .assessment-checkboxes {
      padding: 10px;
    }

    .single-checkbox {
      padding: 10px;
      margin: 0;
    }

    select {
      margin: 5px;
      width: 99%;
      display: inline-block;
    }
  }

  &.bespoke-assessment-panel {
    .assessment-panel-heading {
      .assessment-panel-select-left {
        margin-top: 0;
        margin-bottom: 8px;
      }

      .single-checkbox {
        padding: 0!important;
      }
    }

    .assessment-panel-body {
      .single-checkbox {
        padding: 5px;
      }
    }
  }
}

.panel-display-assessment {
  .panel-body {
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }
}

.standard-assessment-panel__heading {
  &[aria-expanded=true] .fa-caret-down {
    display: none;
  }

  .fa-caret-up {
    display: none;
  }

  &[aria-expanded=true] .fa-caret-up {
    display: inline;
  }
}

.standard-assessment-panel__heading-caret {
  margin-left: 5px;
}

.aistear-tab-0 a, .aistear-panel-0, .aistear-box-0 {
  background-color: #D1A5CF !important;
}

.aistear-tab-1 a, .aistear-panel-1, .aistear-box-1 {
  background-color: #F6A5BC !important;
}

.aistear-tab-2 a, .aistear-panel-2, .aistear-box-2 {
  background-color: #FCE3AD !important;
}

.aistear-tab-3 a, .aistear-panel-3, .aistear-box-3 {
  background-color: #C8E3B0 !important;
}

.assessment-picker-panel.with-search {
  >.panel-heading {
    .panel-title {
      margin-top: 5px;
    }

    .pull-right {
      .btn-xs {
        height: 26px;
      }
    }
  }
}

// LEUVEN //

.leuven-box-1, .leuven-box-2 {
  background-color: #eeeeee;
}

.leuven-radios {
  padding-left: 30px;
}

// PScale //

.pscale-subject-0 {
  background-color: rgb(254,224,203);
}
.pscale-subject-1 {
  background-color: rgb(193,220,207);
}
.pscale-subject-2 {
  background-color: rgb(255,240,210);
}
.pscale-subject-3 {
  background-color: rgb(244,217,193);
}
.pscale-subject-4 {
  background-color: rgb(227,214,233);
}
.pscale-subject-5 {
  background-color: rgb(243,204,198);
}
.pscale-subject-6 {
  background-color: rgb(198,210,235);
}
.pscale-subject-7 {
  background-color: rgb(213,226,201);
}
.pscale-subject-8 {
  background-color: rgb(252,219,228);
}
.pscale-subject-9 {
  background-color: rgb(212,227,238);
}
.pscale-subject-10 {
  background-color: rgb(228,229,243);
}
.pscale-subject-11 {
  background-color: rgb(199,234,251);
}
.pscale-subject-12 {
  background-color: rgb(254,224,203);
}

// KS //

/* Bootstrap .label without the colour */
.ks-label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}


.ks-picker-aspect {
  .dropdown {
    display: inline;
  }
}

.ks-picker-req-group-title {
  font-weight: bold;
}

.ks-picker-req-group-children {
  margin-left: 20px;
}

.ks-ta-enabled-only {
  display: none;
}

.ks-ta-enabled {
  .ks-ta-enabled-only {
    display: initial;
  }
}

.ks-display-req-group-children {
  margin-left: 20px;
}

// Montessori //
.montessori-extension-picker {
  margin-left: 20px;
  margin-top: -8px;
  background: none;
  border: none;
  font-style: italic;
}

.montessori-extension-snapshots {
  padding-left: 40px;
  font-style: italic;
}

.mi-pink {
  color: deeppink;
}

.mi-blue {
  color: #67aaff;
}

.mi-green {
  color: #00B050;
}

.mi-royal-blue {
  color: blue;
}

.mi-red {
  color: red;
}

.mi-purple {
  color: #7030A0;
}

.mi-grey {
  color: grey;
}

.mi-orange {
  color: #FF9900;
}

.mi-brown {
  color: #642512;
}

[data-mi-tooltip], [data-mi-tooltip-selected] {
  border-bottom: 1px dotted;
  cursor: help;
}

.montessori-linked-eylf-info {
  background-color: #5bc0de;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 15px;

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 10px;
  }
}

.label-mi-ref-0 {
  background-color: #e7e7e7;
}

.label-mi-ref-1 {
  background-color: #e28552;
}

.label-mi-ref-2 {
  background-color: #e2c926;
}

.label-mi-ref-3 {
  background-color: #2fbd21;
}

// Remove Picker Button //
.remove-picker {
  margin-left: 5px;
}

// Australian Developmental Milestones
.adm-nav-pill a {
  color: #ffffff !important;
}

.bold-text-node {
  font-weight: 500;
}

@import "./frameworks/cherry_garden";

.pre-key-stage-one-aspect-0, .pre-key-stage-one-aspect-1, .pre-key-stage-one-aspect-2, .pre-key-stage-one-aspect-3 {
  background-color: #ffff00 !important;
}

.pre-key-stage-one-aspect-4, .pre-key-stage-one-aspect-5 {
  background-color: #0066ff !important;
}

.pre-key-stage-one-statement-prefix {
  margin-top: 15px;
}

@import "./colour_palette.scss";

// Generate background colours for pills
@each $colour-name, $colour in $adm-colour-palette {
  /* ADM pill background #{$colour-name} */
  .adm-nav-pill-#{$colour-name} a {
    background-color: $colour !important;
  }
}

// BESPOKE
.bespoke-display {
  margin-bottom: 10px;

  &__text {
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
  }

  &__children {
    margin-left: 15px;
  }
}

.bespoke-summative {
  &__header {
    padding-left: 0 !important;
  }

  &__row,
  &__header {
    p {
      margin: 0;
    }

    .tooltip {
      width: max-content;
      max-width: 400px;
    }

    .rotate-icon.animate {
      transition: all .3s linear;
      margin-right: 8px;
    }

    .rotate-icon.collapsed {
      transform: rotate(-90deg);
    }
  }

  &__category {
    list-style: none;
  }

  &__has-tooltip {
    padding-right: 0;
  }

  &__category,
  &__nested-category {
    margin-left: 0;
    padding-left: 0;
  }

  &__nested-category {
    display: none;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;

    .single-checkbox {
      display: flex;
      align-items: center;
    }
  }

  &__category--has-only-descendants,
  &__category--has-descendants-with-descendants {
    font-weight: 600;
  }

  &__category--has-descendants {
    font-style: italic;
  }
}
