.sticky-table-container {
  overflow: hidden;
}

.sticky-table-top {
  overflow: hidden;

  .table {
    margin-bottom: 0;
  }

  .sticky-table-top-left {
    float: left;
    position: absolute;
    background-color: white;
  }

  .sticky-table-top-right {
    overflow: hidden;
    margin-left: 217px;
  }
}

.sticky-table-main {
  overflow-x: auto;
  min-height: 0.01%;

  table {
    margin-bottom: 0;
  }
}

.sticky-table-fixed-left-inner {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-table-left {
  float: left;
  width: 218px;
  position: absolute;
  overflow: hidden;
  border-right: 1px solid #dddddd;

  .sticky-table-left-inner {
    height: 100%;
    width: 234px;
    overflow-x: hidden;
    overflow-y: scroll;

    table {
      margin-bottom: 0;
    }
  }
}

.sticky-table-right {
  margin-left: 217px;

  .table-bordered {
    margin-top: -38px;
    border-left: none;
  }

  .table-bordered > tbody > tr > td {
    border-left: none;
  }
}

@media screen and (max-width: 767px) {
  .sticky-table-main {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dddddd;
  }
  .sticky-table-main > .table {
    margin-bottom: 0;
  }
  .sticky-table-main > .table > thead > tr > th,
  .sticky-table-main > .table > tbody > tr > th,
  .sticky-table-main > .table > tfoot > tr > th,
  .sticky-table-main > .table > thead > tr > td,
  .sticky-table-main > .table > tbody > tr > td,
  .sticky-table-main > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .sticky-table-main > .table-bordered {
    border: 0;
  }
  .sticky-table-main > .table-bordered > thead > tr > th:first-child,
  .sticky-table-main > .table-bordered > tbody > tr > th:first-child,
  .sticky-table-main > .table-bordered > tfoot > tr > th:first-child,
  .sticky-table-main > .table-bordered > thead > tr > td:first-child,
  .sticky-table-main > .table-bordered > tbody > tr > td:first-child,
  .sticky-table-main > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .sticky-table-main > .table-bordered > thead > tr > th:last-child,
  .sticky-table-main > .table-bordered > tbody > tr > th:last-child,
  .sticky-table-main > .table-bordered > tfoot > tr > th:last-child,
  .sticky-table-main > .table-bordered > thead > tr > td:last-child,
  .sticky-table-main > .table-bordered > tbody > tr > td:last-child,
  .sticky-table-main > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .sticky-table-main > .table-bordered > tbody > tr:last-child > th,
  .sticky-table-main > .table-bordered > tfoot > tr:last-child > th,
  .sticky-table-main > .table-bordered > tbody > tr:last-child > td,
  .sticky-table-main > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.sticky-table-sortable-heading {
  cursor: pointer;
}

.sticky-table-sorting-icon {
  color: #337ab7;
}
