// COLOUR PICKER

.colour-radio {
  display: inline-flex;

  // Style the custom control
  &__control {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 10px;

    &.default {
      border: 2px dashed lightgrey;
      border-radius: 6px;
    }
  }

  // Checked icon hidden unless checked
  &__control-checked-icon {
    visibility: hidden;
  }

  // Small version
  &--small {
    .colour-radio__control {
      width: 50px;
      height: 50px;
    }
    .colour-radio__control-label {
      display: none;
    }
  }

  // Make the native input invisible but keep it functioning
  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  // Style the custom control in checked state
  input:checked + .colour-radio__control {
    border-color: black;

    .colour-radio__control-checked-icon {
      visibility: visible;
    }
  }

  // Style the custom control in focus state
  input:focus + .colour-radio__control {
    box-shadow: 2px 2px 2px #888;
  }
}
