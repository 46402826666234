.navbar-brand.navbar-brand-sm {
  font-size: 13px;
}

/* Helper Classes */

// Add or remove some spacing below or above an element
.space-bottom {
  margin-bottom: 15px;
}

.space-bottom-md {
  margin-bottom: 10px;
}

.space-bottom-sm {
  margin-bottom: 5px;
}

.space-bottom-lg {
  margin-bottom: 25px;
}

.no-space-bottom {
  margin-bottom: 0;
}

.less-space-right {
  margin-right: -25px;
}

.less-space-top {
  margin-top: -25px;
}

.space-top-lg {
  margin-top: 25px;
}

.space-top-ex-lg {
  margin-top: 50px;
}

.space-top-md-bp-sm {
  @media (max-width: 990px) {
    margin-top: 10px;
  }
}

.space-top {
  margin-top: 15px;
}

.space-top-sm {
  margin-top: 5px;
}

.no-space-top {
  margin-top: 0;
}

.space-right {
  margin-right: 15px;
}

.space-right-sm {
  margin-right: 5px;
}

.space-left {
  margin-left: 15px;
}

.space-left-sm {
  margin-left: 5px;
}

.space-left-lg {
  margin-left: 25px;
}

// Labels with larger text
.label-lg {
  font-size: 12px;
}

// Same text style as a label
.text-label {
  font-weight: 700;
}

// Break long words
.word-break {
  word-wrap: break-word;
}

// White space helpers
.white-space-normal {
  white-space: normal;
}

.white-space-no-wrap {
  white-space: nowrap;
}

// Padding helpers
.pad-none {
  padding: 0;
}

.pad-sm {
  padding: 5px;
}

.pad-md {
  padding: 10px;
}

.pad-lg {
  padding: 15px;
}

.margin-none {
  margin: 0;
}

// Make something semi-transparent
.faded {
  opacity: 0.5;
}

// Common size list thumbnail
.list-thumbnail-sm {
  max-height: 20px;
  max-width:20px;
}

.list-thumbnail-md {
  max-height: 50px;
  max-width:50px;
}

// Images in buttons
.btn-xs {
  .btn-img {
    max-height: 15px;
  }
}

// Make the cursor type = default
.cursor-default {
  cursor: default!important;
}

// Make the cursor type = pointer
.cursor-pointer {
  cursor: pointer;
}

// Make the cursor type = help
.cursor-help {
  cursor: help;
}

// This already exists in bootstrap but by redefining it here, it ensures it works in more places
.collapse {
  display: none;
}

/* Fully clickable panel headings */
.panel-default > .panel-heading.cursor-pointer {
  &:hover {
    background-color: #eaeaea;
  }
}

/* No animation collapse */
.no-animation.collapsing {
  -webkit-transition: none;
  transition: none;
}

/* Italic Text */
.emphasis {
  font-style: italic;
}

/* Bold Text */
.strong {
  font-weight: bold;
}

.btn-line-height {
  line-height: 38px;
}

.btn-xs-line-height {
  line-height: 28px;
}

/* Highlight a table row on hover */
.highlight-row:hover {
  background-color: #f1f1f1;
}


/* Page Spacer */
.content-spacer {
  height: 1px;
  width: 100%;
  background-color: #c7c7c7;
}


/* Badge Colour */
.badge-primary {
  background-color: #337ab7;
}

/* Set as the class on a page anchor so that there's an offset for the fixed header */
.anchor {
  display: block;
  position: relative;
  top: -55px;
  visibility: hidden;
}

/* Remove the spacing bootstrap adds to mark elements, it makes text look like it's got a gap */
mark {
  padding-right: 0;
  padding-left: 0;
}

/* Make the cog dropdown menu align right on larger screens, left on smaller screens */
.dropdown-menu-cog {
  @media (min-width: 992px) {
    left: auto;
    right: 0;
  }
}

/* Static version of a button. Use in place of .btn */
.btn-static {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;

  .label {
    position: relative;
    top: -1px;
  }
}

.btn-static-default {
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;

  .badge {
    color: #ffffff;
    background-color: #333333;
  }
}

.btn-static-primary {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #2e6da4;

  .badge {
    color: #337ab7;
    background-color: #ffffff;
  }
}

.btn-static-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c;

  .badge {
    color: #5cb85c;
    background-color: #ffffff;
  }
}

.btn-static-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;

  .badge {
    color: #5bc0de;
    background-color: #ffffff;
  }
}

.btn-static-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #eea236;

  .badge {
    color: #f0ad4e;
    background-color: #ffffff;
  }
}

.btn-static-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a;

  .badge {
    color: #d9534f;
    background-color: #ffffff;
  }
}

.btn-static-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.btn-static-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-static-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-static-block {
  display: block;
  width: 100%;
}

.btn-static-block + .btn-static-block {
  margin-top: 5px;
}

// Automatically clear grids where columns are the same widths
// NB Does not pull through to larger screens (e.g. md does not work at lg screen size, you need to specify both md and lg)
@media (max-width: 767px) {
  .row.auto-clear {
    .col-xs-1:nth-child(12n+1), .col-xs-2:nth-child(6n+1), .col-xs-3:nth-child(4n+1), .col-xs-4:nth-child(3n+1), .col-xs-6:nth-child(2n+1), .col-xs-12 {
      clear: left;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .row.auto-clear {
    .col-sm-1:nth-child(12n+1), .col-sm-2:nth-child(6n+1), .col-sm-3:nth-child(4n+1), .col-sm-4:nth-child(3n+1), .col-sm-6:nth-child(2n+1), .col-sm-12 {
      clear: left;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .row.auto-clear {
    .col-md-1:nth-child(12n+1), .col-md-2:nth-child(6n+1), .col-md-3:nth-child(4n+1), .col-md-4:nth-child(3n+1), .col-md-6:nth-child(2n+1), .col-md-12 {
      clear: left;
    }
  }
}
@media (min-width: 1200px) {
  .row.auto-clear {
    .col-lg-1:nth-child(12n+1), .col-lg-2:nth-child(6n+1), .col-lg-3:nth-child(4n+1), .col-lg-4:nth-child(3n+1), .col-lg-6:nth-child(2n+1), .col-lg-12 {
      clear: left;
    }
  }
}


// Override display

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

// Visible or hidden when collapsed

.visible-collapsed-inline-block {
  display: none;
}

.collapsed {
  .hidden-collapsed {
    display: none;
  }
  .visible-collapsed-inline-block {
    display: inline-block;
  }
}

// Collapse icons
.collapse-toggle-icons {
  &[aria-expanded=true] .collapse-toggle-icons__expand-icon {
    display: none;
  }

  .collapse-toggle-icons__collapse-icon {
    display: none;
  }

  &[aria-expanded=true] .collapse-toggle-icons__collapse-icon {
    display: inline;
  }
}

// Bigger font awesome
.fa-6x {
  font-size: 6em;
}

form.really-inline {
  display: inline;
}

.alert-with-spacing {
  & > p, .alert, ul, ol {
    margin-bottom: 10px;
  }
}

.dropdown-menu-media {
  .media-left {
    padding-right: 5px!important;
  }
}

// Wider modals

.modal-xlg {
  @media (min-width: 992px) {
    width: 95%;
    max-width: 1250px;
  }
}

// Form panel with validation states

.has-error .form-panel {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-panel {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-panel {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

// Overrides for WCAG 2.1 (1.4.3 Minimum Contrast) adherence

.breadcrumb > .active {
  color: #707070; // 4.5:1 contrast ratio
}

.btn-success {
  background-color: #2C882C;
}

// Smaller alert
.alert-sm {
  padding: 4px 10px;
}

//Nav bar management system button
.ms-pill-button {
  background-color: #A03592;
  max-width: 155px;
  text-align: center;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
  padding: -10px, 0, -2px, -2px;
}

@media (max-width: 330px) {
  .hide-cpd {
    display: none !important;
  }
}