.autosave-list--empty {
  display: none;
}

.autosave-indicator {
  text-align: center;
}

.autosave-indicator__saving, .autosave-indicator__saved, .autosave-indicator__failed, .autosave-indicator__connection-failed {
  display: none;
}

.autosave-indicator--saving .autosave-indicator__saving {
  display: block;
}

.autosave-indicator--saved .autosave-indicator__saved {
  display: block;
}

.autosave-indicator--failed .autosave-indicator__failed {
  display: block;
}

.autosave-indicator--connection-failed .autosave-indicator__connection-failed {
  display: block;
}
