// Rainbow colours made using a linear range of HSV colours, keeping S and V constant for each set
// H starts at 210, then -28, -28, -27 and repeat
$rainbow-colour-palette: (

  // Pastels. S = 35, V = 100
  "rb-fresh-air": #a5d2ff,
  "rb-water-spout": #a5fcff,
  "rb-magic-mint": #a5ffd8,
  "rb-mint-green": #a5ffb0,
  "rb-tea-garden": #c4ffa5,
  "rb-pale-goldenrod": #eeffa5,
  "rb-navajo-white": #ffe7a5,
  "rb-peach": #ffbda5,
  "rb-carnation-pink": #ffa5b7,
  "rb-lavender-rose": #ffa5df,
  "rb-rich-brilliant-lavender": #f4a5ff,
  "rb-pale-violet": #caa5ff,
  "rb-maximum-blue-purple": #a5a8ff,

  // Light. S = 60, V = 100
  "rb-french-sky-blue": #66b2ff,
  "rb-electric-blue": #66f9ff,
  "rb-aquamarine": #66ffbc,
  "rb-screamin-green": #66ff77,
  "rb-inchworm": #9bff66,
  "rb-unmellow-yellow": #e2ff66,
  "rb-orange-yellow": #ffd666,
  "rb-pink-orange": #ff8e66,
  "rb-wild-watermelon": #ff6684,
  "rb-rose-pink": #ff66c9,
  "rb-heliotrope": #ed66ff,
  "rb-navy-purple": #a566ff,
  "rb-very-light-blue": #666bff,

  // Dark. S = 60, V = 75
  "rb-cyan-blue-azure": #4c85bf,
  "rb-sea-serpent": #4cbbbf,
  "rb-ocean-green": #4cbf8d,
  "rb-paris-green": #4cbf59,
  "rb-mantis": #74bf4c,
  "rb-middle-green-yellow": #aabf4c,
  "rb-aztec-gold": #bfa04c,
  "rb-copper-red": #bf6b4c,
  "rb-popstar": #bf4c63,
  "rb-mulberry": #bf4c97,
  "rb-fuchsia": #b14cbf,
  "rb-royal-purple": #7c4cbf,
  "rb-ocean-blue": #4c50bf,
);

// These rainbow colours need white foreground text
$rainbow-colour-palette-white-foreground: (
  "rb-cyan-blue-azure",
  "rb-sea-serpent",
  "rb-ocean-green",
  "rb-paris-green",
  "rb-mantis",
  "rb-middle-green-yellow",
  "rb-aztec-gold",
  "rb-copper-red",
  "rb-popstar",
  "rb-mulberry",
  "rb-fuchsia",
  "rb-royal-purple",
  "rb-ocean-blue",
);

$reflection-tag-background-colours: (
  "veyldf-pp": #ea5b63,
  "eylf-pri": #EFAEB2,
  "eylf-pra": #FDC566,
  "nqs": #d12950,
  "nqs-a-1": #A3D4A6,
  "nqs-a-2": #FDEB90,
  "nqs-a-3": #F6C7D1,
  "nqs-a-4": #D4E6B0,
  "nqs-a-5": #FBD18D,
  "nqs-a-6": #E88CBB,
  "nqs-a-7": #89CCEB,
  "eylf": #7BBBB3,
  "veyldf": #2CB34A,
  "veyldf-o-1": #CF1F25,
  "veyldf-o-2": #0072BC,
  "veyldf-o-3": #038543,
  "veyldf-o-4": #8559A0,
  "veyldf-o-5": #F15A22,
  "adm": #E8B908,
  "adm-a-1": #0e86b2,
  "adm-a-2": #B93337,
  "adm-a-3": #8c387c,
  "adm-a-4": #4F9B56,
  "adm-a-5": #1c639e,
  "adm-a-6": #DD7835,
  "apst": #1e8c99,
  "qklg": #d64a40,
);

@mixin reflection-tag-backgrounds {
  @each $class, $colour in map-merge($rainbow-colour-palette, $reflection-tag-background-colours) {
    &.#{$class} {
      background-color: $colour;
      @content;
    }
    // Add this to get white foreground where necessary
    &.fg-bg-#{$class} {
      color: if(index($rainbow-colour-palette-white-foreground, $class), white, inherit);
    }
  }
}

@mixin reflection-tag-foregrounds {
  @each $class, $colour in map-merge($rainbow-colour-palette, $reflection-tag-background-colours) {
    &.fg-#{$class} {
      color: $colour;
      @content;
    }
  }
}

.reflection-tag-pdf {

  .reflection-tag-pdf-category {
    padding: 2px 0 2px 2px;
    @include reflection-tag-backgrounds;

    .reflection-tag-pdf-category-inner {
      padding: 2px;
      page-break-inside: avoid;

      .reflection-tag-pdf-category-text {
        margin-right: 20px;
      }

      .reflection-tag-pdf-category-icon {
        float: right;
        width: 20px;
        text-align: right;
      }
    }

    .reflection-tag-pdf-category-children {
      margin-left: 20px;
      overflow: hidden; // BFC
    }

    .reflection-tag-pdf-category-child-cols-2 {
      box-sizing: border-box;
      width: 50%;
      float: left;
    }

    .reflection-tag-pdf-category-child-cols-3 {
      box-sizing: border-box;
      width: 33%;
      float: left;
    }
  }

  >.reflection-tag-pdf-category {
    margin-top: 5px;
    padding-right: 2px;
    background-color: #f5f5f5;

    &:first-child {
      margin-top: 0;
    }
  }

  &.reflection-tag-pdf-no-background {
    .reflection-tag-pdf-category {
      background-color: white;
    }
  }
}

.reflection-tag-pdf-quick-view {
  display: inline;

  .reflection-tag-pdf-quick-view-category {
    display: inline-block;
    margin-bottom: 5px;
    padding: .3em .6em .3em;
    font-size: 0.9em;
    line-height: 1;
    text-align: left;
    white-space: normal;
    vertical-align: baseline;
    border-radius: .25em;
    @include reflection-tag-backgrounds;
  }

  &.reflection-tag-pdf-quick-view-no-background {
    .reflection-tag-pdf-quick-view-category {
      background-color: white;
      border: 1px solid black;
      color: inherit;
    }
  }
}

.priority-tag-set__low {
  background-color: #FFBF00;
}

.priority-tag-set__medium {
  background-color: #F79862;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

.priority-tag-set__high {
  background-color: #FC6600;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

.dm2020-checkpoint-description {
  font-size: 0.9em;
}
