// REVIEW

.bespoke-flag-set-review {
  &__changes-list {
    padding: 10px 0 10px 0;
  }

  &__changes-list-item {
    list-style: none;
    padding-bottom: 10px;

    &--highlighted {
      @extend .bespoke-flag-set-review__changes-list-item;

      color: blue;
    }
  }

  &__controls {
    padding-bottom: 25px;
  }

  &__navigation {
    font-size: 1.5em;
  }

  &__conclusion {
    text-align: center;
    padding: 15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  &__conclusion-text {
    padding-bottom: 10px;
  }

  &__slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #C4C4C4;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-bottom: 15px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #007BFF;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #007BFF;
      cursor: pointer;
    }
  }

  &__slider-annotation {
    width: 100%;
    background-color: #007BFF;
    color: #FFF;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 30px 0;
  }
}

// Review for CSV Import
.review-framework-import-editor {
  display: block!important; /* Overwrite display:table default for the root editor ID CSS for overflow */
  max-height: 600px;
  overflow: scroll;
}
