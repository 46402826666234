.margin-bottom {
  margin-bottom: 10px;
}

.margin-bottom-lg {
  margin-bottom: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
}

.zero-padding-top {
  padding-top: 0;
}

.zero-margin-bottom {
  margin-bottom: 0;
}

.padding-bottom-xlg {
  padding-bottom: 25px;
}

.very-pale-yellow-background {
  background-color: #ffffcc;
}

.very-pale-red-background {
  background-color: #ffcccc;
}

.very-pale-lime-green-background {
  background-color: #ccffcc;
}

.very-pale-orange-background {
  background-color: #ffdfd3;
}

.slightly-desaturated-magenta {
  background-color: #cc99cc;
}

.slightly-desaturated-cyan {
  background-color: #99cccc;
}

.full-height {
  height: 100%;
}

.md-pull-left {
  @media(min-width: 992px) {
    float: left !important;
  }
}

.md-pull-right {
  @media(min-width: 992px) {
    float: right !important;
  }
}
