/*Child Overview*/

.child-overview-media {
  margin-bottom: 15px;

  .media-body {
    padding-top: 18px;
  }
}

.text-center {
  text-align: center;
}

.child-tag {
  margin: 5px 1px 5px 0;
}
