.likeaction {
  color: grey;
  cursor: pointer;
}

.likeaction:before {
  font-family: 'FontAwesome';
  content: '\f087';
}

.likecommentaction {
  color: grey;
  cursor: pointer;
}
.likecommentaction:before {
  font-family: 'FontAwesome';
  content: '\f087';
}

.like {
  display:inline-block;
}

.liked {
   color: rgba(160, 193, 18, 1.0);
 }

.likecountchildren {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  border: 1px solid transparent;
  white-space: nowrap;
  user-select: none;
}

.likecountchildren:before {
  font-family: 'FontAwesome';
  content: '\f087';
}

