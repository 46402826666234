#uploadedDocuments {
  .report-uploaded-document {
    &[data-document-id="0"] {
      display: none;
    }
  }
}

.report-document-upload-progress {
  margin-top: 15px;
  display: none;
}

@media only screen and (min-width: 680px) {
  .custom-report-list {
    margin-top: -23px;
  }
}

@media only screen and (max-width: 680px) {
  .custom-report-list {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .custom-report-list {
    display: table;
    margin: 15px auto 0px;
  }
}

@media only screen and (max-width: 400px) {
  .custom-report-list-text {
    display: table;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 680px) {
  .custom-report-list-text {
    margin-right: 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .custom-report-btn-title {
    white-space: normal
  }
}

@media only screen and (min-width: 1200px) {
  .report-update-button {
    font-size: 12px;
    margin-left: 39px;
  }
}

@media only screen and (max-width: 1200px) {
  .report-update-button {
    font-size: 12px;
    display: table;
    margin: 0px auto;
  }
}