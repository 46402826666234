/**
 OLj Submission Styles
 **/

table#cohortSubmission {
  width: 100%;
}

table#cohortSubmission td
{
  padding: 0px;
  margin: 0px;
}

table#cohortSubmission td[data-use=children]
{
  width: auto;
}

ul#cohortTabs li
{
  height: 30px;
}

table#cohortChildren {
  width: 100%;
}

table#cohortChildren td
{
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #DBE4EF;
  border-left: 1px solid #DBE4EF;
  color: #808080;
  border-bottom: 1px solid #DBE4EF;
  font-size: 12px;
  height: 40px;
  padding-left:4px;
}

table#cohortChildren tr
{
  background: #f6f8fb;
}

table#cohortChildren tr:nth-child(odd)
{
  background-color: #e4eaf3;
}

table#areaSelection {
  width: 100%
}

table#areaSelection td.header
{
  border-right: 1px solid #f6f8fb;
  text-align: center;
  height: 40px;
  background: #b6c7db;
  color: #1d3652;
}

table#areaSelection tr[data-use=scores]
{
  background: #f6f8fb;
}

/* Use even here because first child TD is blank */
table#areaSelection tr[data-use=scores]:nth-child(even)
{
  background-color: #e4eaf3;
}

table#areaSelection tr[data-use=scores] td
{
  height: 38px; /* Don't forget the borders */
  border-top: 1px solid #DBE4EF;
  border-right: 1px solid #DBE4EF;
  color: #808080;
  padding-left:4px;
}

table#areaSelection tr[data-use=scores] td select
{
  margin-top: 12px;
}

table#areaSelection tr[data-use=scores] td.manual
{
  background-color: #faeaea;
}

table#cohortChildStats
{
  width: 100%;
  float: right;
}

@media only screen and (max-width : 990px) {
  table#cohortChildStats
  {
    width: 50%;
  }
}

table#cohortChildStats td
{
  border: 1px solid #b7c0cb !important;
  margin: 4px 2px 4px 2px !important;
  vertical-align: bottom;
  padding-left: 0px !important;
}

table#cohortChildStats td[data-chosen]
{
  background-color: #dc9af8 !important;
}

table#cohortChildStats td div[data-box]
{
  background-color: #aae7b9;
  margin: 0px 2px 0px 2px !important;
}

table#cohortChildStats td div[data-box=expected]
{
  background-color: #6dbf81;
}

table#cohortChildStats td div[data-box=exceeding]
{
  background-color: #298a42;
}

.label-assessed {
  float: right;
  margin-right: 10px;
  font-size: 10px;
}

.eyfsp-50 {
  width: 50%;
  float: left;
}
