#ks-analysis-subject-filters-categories {

  label {
    font-weight: normal;
    margin-left: 20px;
  }

  .indent-1 {
    margin-left: 20px;
  }

  .indent-2 {
    margin-left: 40px;
  }

  .indent-3 {
    margin-left: 60px;
  }

  .indent-4 {
    margin-left: 80px;
  }

  .indent-5 {
    margin-left: 100px;
  }

  button {
    border-color: transparent;
  }

  button:hover {
    border-color: #cccccc;
  }

}

.table-span-list {
  border: 1px solid #cccccc;
  padding: 2px;
  margin-left: 5px;
  border-radius: 5px;

  &:first-child {
    margin-left: 0;
  }
}