.about-me-text {
  font-family: 'Indie Flower', sans-serif;
  font-size:18px;
}

@media (min-width: 991px) {
  .about-me-enabled-checkbox {
    padding-top: 30px;
  }
}

.about-me-help-list {
  list-style: none;
  padding: 0;
  margin-bottom: 1.2em;
}
