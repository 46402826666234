$label-small-font: normal normal bold 24px/1 'comicneuebold', cursive, sans-serif;
$label-medium-font: normal normal bold 28px/1 'comicneuebold', cursive, sans-serif;
$label-large-font: normal normal bold 30px/1 'comicneuebold', cursive, sans-serif;
$label-text-padding-left: 50px;
$label-width: 340px;
$label-height: 180px;
$label-margin: 20px;
$label-padding: 8px;
$page-margin: 0px;

.label-container {
  margin: 10px;

  &:hover {
    background-color: rgba(172, 210, 228, 0.2);
    border: 1px solid rgba(0,0,0,0.5);
    cursor: pointer;
  }
}

.bottom-margin {
  margin-bottom: 10px;
}

.bottom-margin-video {
  margin-bottom: 20px;
}

.label-style-name {
  text-align: center;
  font-weight: bold;
}

.no-labels-info {
  text-align: center;
}

.label-style-description {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  overflow: hidden;
  width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label-preview-image {
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-container {
  margin-right: 10px;
}

.suggest-btn {
  margin-left: 10px;
}

.label-style-1 {
  margin: $label-margin;
  padding: $label-padding;
  height: $label-height;
  line-height: $label-height;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: #ffffff;
  border: 6px solid rgba(232, 4, 205, 1);
  font: $label-small-font;
}

.text-style-1 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  font: $label-large-font;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.label-style-2 {
  margin: $label-margin;
  padding: $label-padding;
  height: $label-height;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: #e9ffbf;
  border: 6px solid rgb(99, 232, 70);
  font: $label-small-font;
}

.text-style-2 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  text-align: center;
  color: rgb(58, 117, 39);
}

.label-style-3 {
  margin: $label-margin;
  height: $label-height;
  padding: $label-padding;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: #a2b5ff;
  border: 6px solid rgb(49, 20, 232);
  font: $label-medium-font;
}

.text-style-3 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}

.label-style-4 {
  margin: $label-margin;
  height: $label-height;
  padding: $label-padding;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: #c5f8ff;
  border: 6px solid rgb(232, 157, 24);
  font: $label-large-font;
}
.text-style-4 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}

.label-style-5 {
  margin: $label-margin;
  padding: $label-padding;
  height: $label-height;
  border: 6px solid orange;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  font: $label-large-font;
  color: rgba(0, 0, 0, 1);
  background: #ffffff;
}
.text-style-5 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}

.label-style-6 {
  margin: $label-margin;
  height: $label-height;
  padding: $label-padding;
  border: 6px solid orange;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  font: $label-large-font;
  background: #ffffff;
}

.text-style-6 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}


.label-style-7 {
  margin: $label-margin;
  height: $label-height;
  padding: $label-padding;
  border: 6px solid orange;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  font: $label-large-font;
  background: #ffffff;
}

.text-style-7 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}


.label-style-8 {
  margin: $label-margin;
  height: $label-height;
  padding: $label-padding;
  border: 6px solid orange;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  font: $label-large-font;
  background: #ffffff;
}

.text-style-8 {
  margin-top: $label-height / 5;
  padding-left: $label-text-padding-left;
  overflow: hidden;
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
}

.label-suggest-style {
  margin: 10px;
  padding: 10px;
  text-align:center
}

.style-suggestion {
  text-align: center;
}
