// User Select

.user-select2-portrait {
  width: 25px;
}

.user-select-confirm-buttons-container {
  z-index: 1051;
  width: 100%;

  &.attached {
    position: fixed;
    bottom: 0;

    @media (min-width: 768px) {
      margin-left: -7px;
    }
  }

  &.detached {
    .user-select-confirm-buttons {
      border-radius: 6px;
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }
  }

  .user-select-confirm-buttons {

    background: white;
    padding: 10px;

    border-top: 1px solid #e5e5e5;

    @media (min-width: 768px) {
      width: 598px;
      margin: auto;
    }

    .pull-left {
      padding-top: 8px;
    }

    .pull-right {
      text-align: right;
    }
  }
}

.user-select-item {
  i.fa-square-o {
    margin-right: 1px;
  }
}

.user-tag {
  margin: 5px 1px 5px 0;
}

.user-tag-label {
  padding: 4px 4px 6px 4px;
}

/* Profile Pictures Update */

.profile-picture-update-tile {

}

.profile-picture-update-tile--drop-active .panel {
  background-color: #d9edf7;
}

.profile-picture-update-tile__name {
  width: 100%;
  margin-top: 0;
  font-size: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-picture-update-tile__picture-container {
  position: relative;
  height: 200px;
  text-align: center;
}

.profile-picture-update-tile__picture {
  height: 100%;
  width: auto;
}

.profile-picture-update-tile__picture-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-weight: bold;
}

.profile-picture-update-tile__overlay-icon {
  font-size: 4em;
  margin-bottom: 20px;
}

.profile-picture-update-tile__buttons {
  height: 34px;
  overflow: hidden;
}

.profile-picture-update-tile__progress-bar-container {
  height: 34px;
  margin: 0;
}

.profile-picture-update-tile__progress-bar {
  min-width: 2em;
  line-height: 34px;
}
