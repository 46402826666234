div.flags-quick-view {
  position: relative;
  display: inline;
  .flags-quick-view-category-linked {
    padding: 0;
    a {
      color: inherit;
      display: inline-block;
      padding: 1px 5px;
      white-space: normal;
      text-align: left;
      &:hover {
        text-decoration: none;
        background: #00000033;
      }
    }
  }
}
