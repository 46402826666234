.vr-flex-h-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vr-flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.vr-flex-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.vr-btn-disabled {
  pointer-events : none;
}

.vr-day-btn {
  padding: 5px !important;
  margin: 5px !important;
  width: 14%;
}

.vr-month-btn {
  padding: 5px !important;
  margin: 5px !important;
  width: 25%;
}

.vr-day-btn.active, .vr-month-btn.active{
  color: #fff !important;
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}

.vr-normal-type {
  font-weight: normal;
}

.vr-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.vr-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.vr-instructions {
  font-weight: bold;
  padding: 20px;
}

.vr-float-right {
  float: right;
}

.vr-float-left {
  float: left;
}

.vr-answers {
  display:flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 5px;
}

.vr-answers-prompt {
  font-weight: bold;
  text-align: center;
}

.vr-answers-day {
  width: 50px;
  border: 1px solid #999999;
  background-color: #eeeeee;
  font-weight: bold;
}

.vr-answers-month {
  width: 100px;
  border: 1px solid #999999;
  background-color: #eeeeee;
  font-weight: bold;
}

.js-unverified {
  @media (min-width: 991px) {
    display: flex;
    justify-content: center;
  }
}
