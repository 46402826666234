.focus-mode {

  padding-top: 50px;

  .navbar-top {
    display: none;
  }

  .navbar-bottom {
    display: none;
  }

  .navbar-child {
    display: none;
  }

  .top-left-icon-img{
    display: none;
  }

  .feedback-wrapper {
    margin-top: 0;
  }

  .header-area {
    display: none;
  }

}
