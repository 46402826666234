.observation-item {
  &.report-item {
    .media-left {
      @media (max-width: 767px) {
        // Icons are smaller on smaller screens
        width: 45px;

        // Stop the status label overflowing
        .report-status-label {
          display: inline-block;
          max-width: 45px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
        }
      }
      @media (min-width: 768px) {
        // The "Awaiting Deletion" label is a bit long so we'll make it all line up with this
        width: 110px;
      }
    }

    &.status-draft {
      border-left: 5px solid #f0ad4e;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #F0D199; /* Old browsers */
      background: -moz-linear-gradient(left,  #F0D199 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F0D199), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #F0D199 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F0D199', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-staff-only {
      border-left: 5px solid #5fc4fc;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9f4fc+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #c9f4fc; /* Old browsers */
      background: -moz-linear-gradient(left,  #c9f4fc 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #c9f4fc 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9f4fc', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }

    &.status-awaiting-deletion {
      border-left: 5px solid red;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9c7c7+0,ffffff+5,ffffff+5,ffffff+36 */
      background: #f9c7c7; /* Old browsers */
      background: -moz-linear-gradient(left,  #f9c7c7 0%, #ffffff 5%, #ffffff 5%, #ffffff 36%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,#f9c7c7), color-stop(5%,#ffffff), color-stop(5%,#ffffff), color-stop(36%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* IE10+ */
      background: linear-gradient(to right,  #f9c7c7 0%,#ffffff 5%,#ffffff 5%,#ffffff 36%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9c7c7', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }
}

.report-item-tags {
  margin-top: 5px;

  .child-tag {
    margin-top: 0;
  }

  .btn-xs, .btn-static-xs {
    margin-bottom: 5px;
  }

  > .label {
    vertical-align: text-top;
  }
}

// TODO DRY this up with reflection-notes-short
.report-notes-short {
  display: block;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.reports-comments-disabled {
  [data-action="add_comment"], #commentForm, .deleteComment, .deleteCommentCog, .inline-edit {
    display: none;
  }
  #oljPageComments.page-comments__empty {
    display: none;
  }
}

.reports-likes-disabled {
  [data-like-url] {
    display: none;
  }
}

.reports-section {

  .reports-section-hidden-visible-inline, .reports-section-hidden-visible-block, .reports-section-hidden-visible-inline-block, .reports-section-hidden-visible-list-item {
    display: none;
  }

  &.reports-section-hidden {
    .reports-section-hidden-invisible {
      display: none;
    }
    .reports-section-hidden-visible-inline {
      display: block;
    }
    .reports-section-hidden-visible-block {
      display: block;
    }
    .reports-section-hidden-visible-inline-block {
      display: inline-block;
    }
    .reports-section-hidden-visible-list-item {
      display: list-item;
    }
  }

  [data-category-type] {
    .reports-assessment-category-hidden-visible-inline, .reports-assessment-category-hidden-visible-block, .reports-assessment-category-hidden-visible-list-item {
      display: none;
    }

    &.reports-assessment-category-hidden {
      .reports-assessment-category-hidden-invisible {
        display: none;
      }
      .reports-assessment-category-hidden-visible-inline {
        display: inline;
      }
      .reports-assessment-category-hidden-visible-block {
        display: block;
      }
      .reports-assessment-category-hidden-visible-list-item {
        display: list-item;
      }
    }
  }
}

.reports-section__heading {
  padding-bottom: 5px;
}

.reports-section__title {
  display: inline;
  margin-bottom: 5px;
}

.reports-section__controls {
  font-size: initial;
  margin-bottom: 5px;
}

.reports-form-row {

}

.reports-form-row__field {
  display: block;
}

.reports-form-row-hidden-visible-inline, .reports-form-row-hidden-visible-block, .reports-form-row-hidden-visible-inline-block, .reports-form-row-hidden-visible-list-item {
  display: none;
}

.reports-form-row--hidden {
  .reports-form-row-hidden-invisible {
    display: none;
  }
  .reports-form-row-hidden-visible-inline {
    display: block;
  }
  .reports-form-row-hidden-visible-block {
    display: block;
  }
  .reports-form-row-hidden-visible-inline-block {
    display: inline-block;
  }
  .reports-form-row-hidden-visible-list-item {
    display: list-item;
  }

  .reports-form-row__field {
    display: none;
  }
}

.reports-form-row__signature {

}

.reports-form-row__signature-value {
  display: none;
}

.reports-form-row__signature-value-image {
  border: 1px solid #ddd;
}

.reports-form-row--signature__no-value {
  display: block;
  font-weight: bold;
}

.reports-form-row--signature__sign-button {
  display: inline-block;
}

.reports-form-row--signature__clear-button {
  display: none;
}

.reports-form-row__signature--signed {
  .reports-form-row__signature-value {
    display: block;
  }
  .reports-form-row--signature__no-value {
    display: none;
  }
  .reports-form-row--signature__sign-button {
    display: none;
  }
  .reports-form-row--signature__clear-button {
    display: inline-block;
  }
}

.reports-form-row__relative-placeholder .well p:last-child {
  margin-bottom: 0;
}

.reports-form-row__relative-field {
  display: none;
}

.reports-form-row__relative.reports-form-row__relative--editing {
  .reports-form-row__relative-edit-button {
    display: none;
  }
  .reports-form-row__relative-placeholder {
    display: none;
  }
  .reports-form-row__relative-field {
    display: block;
  }
}

.reports-row-controls {
  margin-bottom: 5px;
}

.reports-dropdown-menu {

}

.reports-dropdown-menu-item {

}

.reports-dropdown-menu-item-unchecked-icon {
  display: inline;
}

.reports-dropdown-menu-item-checked-icon {
  display: none;
}

.reports-dropdown-menu-item--checked {
  .reports-dropdown-menu-item-unchecked-icon {
    display: none;
  }
  .reports-dropdown-menu-item-checked-icon {
    display: inline;
  }
}

.reports-contributing-obs__status {
  margin-right: 5px;
}

.reports-send-aspect-table {
  table-layout: fixed;
  width: 100%;

  th, td {
    overflow-wrap: break-word;
  }

  th {
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 25%;
    }
  }
}

.reports-view-text-area {
  .reports-view-text-area__label {
    margin-top: 20px;
  }
}

.reports-view-signature {
  margin-bottom: 10px;
}

.reports-view-signature__label {
}

.reports-view-signature__image {
  border: 1px solid #ddd;
}

.reports-view-signature__no-value {
  font-weight: bold;
}

.reports-quick-edit {
  color: #777777;
  margin-left: 5px;
  font-size: 14px;
}

.reports-unsaved-tags {
  .label {
    display: inline-block;
  }
}

.update-report-box {
  font-size: 100%;
}

.custom-report-button {
  @media (min-width: 1200px) {
    width: 247.5px;
  }
}

.space-top-very-sm {
  margin-top: -15px;
}

.custom-panel-padding {
  padding: 15px 15px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #333333;
  background-color: #f5f5f5;
}

.custom-report-font-size {
  font-size: 15px;
}

.custom-report-icon::before {
  content: url('../../images/icons/custom_report.svg');
  @media (max-width: 767px) {
    filter: grayscale(100%);
  }
}

.custom-report-font-colour {
  color: #962D29;
}

