.folder-list-item {
  .folder-details {
    min-height: 50px;
  }
}

.file-buttons {
  display: flex;
}

.btn-default > input[type="radio"],
.btn-default > input[type="checkbox"] {
  margin: 0px;
}

.file-tooltip {
  @media (max-width: 768px) {
    padding: 0 0.35em;
    font-size: 1.35em;
  }
}

.file-sharing {
  margin-top: 15px;

  label {
    font-weight: normal;
    margin-bottom: 0;
    margin-left: 5px;
  }

  //label[for=individualRelativeShare], label[for=staffShare] {
  //  color: darkgray;
  //}

  .file-sharing-title {
    font-weight: bold;
  }

  .request-feature {
    font-size: smaller;
  }
}

.more-children-dropdown{
  text-decoration: none;
  color: #333333;
}

.more-children-dropdown:hover{
  text-decoration: none;
  color: #333333;
}

.files-dropdown-menu-fill-width.dropdown {
  position: static;
}

.files-dropdown-menu-fill-width.dropdown > .dropdown-menu {
  left: auto;
}
.files-dropdown-menu-fill-width.dropdown > .dropdown-menu > li > a {
  white-space: normal;
}

.files-dropdown-more-children {
  padding: 5px 5px 5px 10px;
}
