.userPhoto.thumb {
  max-height: 50px;
  max-width: 50px;
}

.userPhoto.thumb.lg {
  max-height: 70px;
  max-width: 70px;
}

.userPhoto.thumb-125-px {
  max-height: 130px;
  max-width: 130px;
  padding: 5px;
}

.userPhoto {
  margin: 0px auto;
  padding: 1px;
  background: #fff;
  border-radius: 4px;
}

.userPhoto--muted {
  opacity: 0.5;
}
