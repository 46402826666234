/* Loading */

#oljAjaxLoader {
  background: #95C715;
  border: 1px solid #7BA60D;
  color: #fff;
  text-align: center;
  padding: 5px 0 8px;
  width: 8%;
  min-width:53px;
  top: 0px;
  left: 46%;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-radius: 0 0 5px 5px;
  z-index: 10000;
  position: fixed;
  -moz-box-shadow: 0px 3px 5px rgba(0,0,0,0.2), inset 0px -1px 0px rgba(255,255,255,0.2);
  -webkit-box-shadow: 0px 3px 5px rgba(0,0,0,0.2), inset 0px -1px 0px rgba(255,255,255,0.2);
  box-shadow: 0px 3px 5px rgba(0,0,0,0.2), inset 0px -1px 0px rgba(255,255,255,0.2);
  display: none;
}

/* Filters */

.filter-button-container {
  text-align: center;

  top: auto;
  width: 100%;
  left: auto;

  button {
    white-space: normal;

    .filter-value {
      font-weight: bold;
      font-style: italic;
    }
  }
}

.filter-button-push {
  height: 34px;
}

.filter-modal-row {
  [class*='col-'] { min-height: 0 } // IE11 doesn't understand unset, which is what we really want to do
}

/* Notifications Menu */
.notifications-menu {
  width: 340px;
  padding: 10px;

  .list-group {
    margin: -10px -10px 0 -10px;
    margin-bottom: 10px;

    .list-group-item {
      border: none;
    }
  }
}

.select2-selection--multiple {
  padding-right: 25px;
  background: url("/images/icons/chevron_down.png") no-repeat;
  background-position: 100%;
}

.uniqueClass {
  padding-left:0;
}

/* Bulk Options */
.bulk-options {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #fff;
  padding: 10px;
  z-index: 15000;
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}


// Skip to content for screen readers
.skip-to-content {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15000;
  overflow: hidden;
  width: 1px;
  height: 1px;
  display: block;
  border-radius: 5px;

  &:focus {
    padding: 20px;
    height: auto;
    width: auto;
  }
}

.missing-heading {
  height: 0px;
  width: 0px;
  color: yellow;
  background-color: green;
  overflow: hidden;
}

.missing-heading::after {
  content: "Missing Heading";
}

#main-content:target {
  scroll-margin-top: 72px;
}

.on-hover-reduce-opacity:hover {
  opacity: 0.6;
  cursor: pointer;
}
