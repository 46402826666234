// Assessment Settings //

// Next steps
.ns_container {
  margin: 2px;
  border: 1px solid black;
}

.ns_container > span {
  margin: 5px;
  font-weight: bold;
}

.ns_container > small > button {
  margin: 5px;
}

.ns_item_panel {
  margin: 5px 0;
  border: 1px solid #c9c9c9;
  padding: 5px;
  vertical-align: top;
}

.ns_item_panel a.remove_button {
  float: right;
  color: #3c3c3c;
  margin-left: 5px;
  font-size: 18px;
  text-decoration: none;
}

.ns_item_panel span {
  word-wrap: break-word;
  max-width: 500px;
  display: block;
}

// Contact Details //

.contact-details__alert-link {
  text-decoration: underline;
}

// Assessments //
.settings-framework-group-panel {
  margin: -10px -15px -10px -15px;
  border: none;

  .panel-heading {
    padding: 5px 15px;
    border-radius: 0;
  }
}

.settings-framework-container {
  border-right: 1px solid #d6d6d6;
}

.settings-framework-left {
  width: auto;
  display: inline-block;
  max-width: 250px;
}

.settings-framework-right {
  @media only screen and (max-width : 768px) {
    float: none;
  }
}

.bespoke-flags-sets-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: 10px;
  }
  &__add-new {
    margin-left: auto;
  }
  &__collapse {
    margin-left: 18px;
    margin-bottom: 8px;
    align-self: flex-end;
  }
}

@media only screen and (max-width : 990px) {
  .settings-framework-container {
    border-right: 0;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}

.settings-framework-right {
  padding-right: 15px;
}

.settings-framework-enabled-text {
  display: inline;
}

// Scheduled pages

.scheduled-pages-options__enabled, .scheduled-pages-options__disabled {
  display: none;
}

.scheduled-pages-options--enabled .scheduled-pages-options__enabled {
  display: block;
}

.scheduled-pages-options--disabled .scheduled-pages-options__disabled {
  display: block;
}

// FLAGS

@media only screen and (min-width : 769px) {
  .flag-provider-tile--odd {
    padding-left: 0;
    padding-right: 0;
  }
}

.flag-provider-tile__header-buttons {
  padding-bottom: 10px;
  @media only screen and (min-width : 769px) {
    padding-right: 15px;
  }
}
