.page-tour-help {
  cursor: pointer;
}

.page-tour-tooltip {
  @media (max-width: 400px) {
    font-size: 0.75em;
  }
}

.page-tour-tooltip--wide {
  @extend .page-tour-tooltip;

  width: 300px;
}
